import React from 'react';
import PropTypes from 'prop-types';

const RenderHtml = ({htmlContent, altTagInfo}) => {
    return (
        <div alt={altTagInfo} dangerouslySetInnerHTML={{__html:htmlContent}}></div>
    );
}


RenderHtml.propTypes = {
    cmsContent: PropTypes.object
};

export default (RenderHtml);