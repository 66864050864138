import React from 'react';
import PropTypes from 'prop-types';
import CmsKeys from 'js/utils/cmsKeys';
import CmsContent from 'components/CmsContent';
import {getTextFromContent} from 'js/utils/cmsUtils';
import {connect} from 'react-redux';

const TechnicalDifficulties = ({cmsContent}) => {
    return (
        <section className="section section--shadow">
            <div className="container">
                <h1 className="heading heading--secondary">
                    <CmsContent pageName={CmsKeys.technicalDifficultiesPageTitle}/>
                </h1>
                <div className="row">
                    <div className="col-xs-12 col-md-10 col-md-offset-1 text-center" alt={CmsKeys.technicalDifficultiesPageMessage}
                        dangerouslySetInnerHTML={{__html: getTextFromContent(cmsContent, CmsKeys.technicalDifficultiesPageMessage)}}>
                    </div>
                </div>
            </div>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent
    };
}

TechnicalDifficulties.propTypes = {
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(TechnicalDifficulties);