import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import CmsContent from 'components/CmsContent';
import Title from 'components/Title';
import CmsKeys from 'js/utils/cmsKeys';
import {toMoneyFormat} from 'js/utils/formatUtils';

const STRING_TYPE = 'string';
const MONEY_TYPE = 'money';
const DATE_TYPE = 'date';

class ShLoanInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    buildRow = (data, array, key, type) => {
        let rightContent = this.buildRightContent(data, type);

        array.push(
            <tbody key={key}>
            <tr>
                <th className="table-content-left"><CmsContent pageName={key}/></th>
                {rightContent}
            </tr>
            </tbody>
        );
    };

    buildRightContent = (data, type) => {
        switch (type) {
            case STRING_TYPE : {
                return <td className="table-content-right">{data}</td>;
            }
            case MONEY_TYPE : {
                return <td className="table-content-right">{toMoneyFormat(data)}</td>;
            }
            case DATE_TYPE : {
                return <td className="table-content-right">{data ? <Moment date={data} format="MM/DD/YYYY"/> : ''}</td>;
            }
            default :
                return (
                    <td className="table-content-right"/>
                );
        }
    };

    renderLoanStatusAccordion = () => {
        let rows = [];
        const loan = this.props.loan;

        this.buildRow(loan.projectedCloseDate, rows, CmsKeys.shLoanInfoChildRowLabelOne, DATE_TYPE);
        this.buildRow(loan.stage, rows, CmsKeys.shLoanInfoChildRowLabelTwo, STRING_TYPE);
        this.buildRow(loan.silverHillStatus, rows, CmsKeys.shLoanInfoChildRowLabelThree, STRING_TYPE);

        return this.renderAccordion(CmsKeys.shLoanInfoParentRowLabelOne, rows, 0);
    };

    renderBrokerCompensationAccordion = () => {
        let rows = [];
        const loan = this.props.loan;

        this.buildRow(loan.buyupYspDisplay, rows, CmsKeys.shLoanInfoChildRowLabelFour, MONEY_TYPE);
        this.buildRow(loan.promotionPricing, rows, CmsKeys.shLoanInfoChildRowLabelSix, MONEY_TYPE);

        return this.renderAccordion(CmsKeys.shLoanInfoParentRowLabelTwo, rows, 1);
    };

    renderAppraisalInfoAccordion = () => {
        let rows = [];
        const loan = this.props.loan;

        this.buildRow(loan.appraisalOrderDate, rows, CmsKeys.shLoanInfoChildRowLabelSeven, DATE_TYPE);
        this.buildRow(loan.appraisalDeliveryDate, rows, CmsKeys.shLoanInfoChildRowLabelEight, DATE_TYPE);
        this.buildRow(loan.normalValue, rows, CmsKeys.shLoanInfoChildRowLabelNine, MONEY_TYPE);

        return this.renderAccordion(CmsKeys.shLoanInfoParentRowLabelThree, rows, 2);
    };

    renderTitleInfoAccordion = () => {
        let rows = [];
        const loan = this.props.loan;

        this.buildRow(loan.titleOrderDate, rows, CmsKeys.shLoanInfoChildRowLabelTen, DATE_TYPE);
        this.buildRow(loan.titleReceivedDate, rows, CmsKeys.shLoanInfoChildRowLabelEleven, DATE_TYPE);
        this.buildRow(loan.titleReviewedDate, rows, CmsKeys.shLoanInfoChildRowLabelTwelve, DATE_TYPE);

        return this.renderAccordion(CmsKeys.shLoanInfoParentRowLabelFour, rows, 3);
    };

    renderAccordion = (titleCmsKey, rows, index) => {
        const checkboxId = `accordion-${index}`;

        return (
            <tbody>
            <tr>
                <td colSpan="2">
                    <div className="row">
                        <div className="col">
                            <div className="tabs">
                                <div className="tab">
                                    <input type="checkbox" id={checkboxId}/>
                                    <label className="tab-label" htmlFor={checkboxId}>
                                        <CmsContent pageName={titleCmsKey}/>
                                    </label>
                                    <div className="tab-content">
                                        <table className="table rwd-table">
                                            {rows}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        );
    };

    render() {
        return (
            <main className="main-content" id="main-content" role="main">
                <Title title={<CmsContent pageName={CmsKeys.shDocumentCenterMainTitle}/>}/>
                <section className="section">
                    <div className="container">
                        <section className="section row">
                            <div className="col-md-1 col-sm-12"/>
                            <div className="col-md-7 col-sm-12">
                                <div className="bls-table-content">
                                    <table>
                                        <caption className="bls-block" alt="Loan-Details-Content-Five">
                                            <CmsContent pageName={CmsKeys.shLoanInfoTitle}/>
                                            <br/>
                                            {this.props.loan.loanNumber} &nbsp; {this.props.loan.loanName}
                                        </caption>
                                    </table>
                                    <table className="table rwd-table">
                                        {this.renderLoanStatusAccordion()}
                                        {this.renderBrokerCompensationAccordion()}
                                        {this.renderAppraisalInfoAccordion()}
                                        {this.renderTitleInfoAccordion()}
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-1"/>
                        </section>
                    </div>
                </section>
            </main>
        );
    }
}

ShLoanInformation.propTypes = {
    cmsContent: PropTypes.object,
    loan: PropTypes.object
};

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent,
        loan: state.loan
    };
}

export default connect(mapStateToProps)(ShLoanInformation);