import React, {Component} from 'react';
import InputFocusClass from 'js/external/components/input-focus';
import Title from 'components/Title';
import LoginForm from 'components/Bayview/LoginForm';
import RegistrationForm from 'components/Bayview/RegistrationForm';

import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';

import {connect} from 'react-redux';
import {setUser} from 'js/actions';
import axios from 'axios';
import ApiConstants from 'js/utils/apiConstants';
import UiConstants from 'js/utils/uiConstants';
import _ from 'lodash';
import Loading from 'components/Loading';
import GeneralModal from "components/GeneralModal";
import {getTextFromContent} from "js/utils/cmsUtils";
import {getServicerSoldTo} from 'js/utils/userUtils';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            serverError: '',
            userError: '',
            showAnnouncementPopup: true
        }

        this.handleUserAccess = this.handleUserAccess.bind(this);
        this.onAnnouncementPopupClose = this.onAnnouncementPopupClose.bind(this);
    }

    componentDidMount() {
        new InputFocusClass();
    }

    componentDidUpdate() {
        new InputFocusClass();
    }

    handleUserAccess(values) {
        this.setState({loading: true});

        axios.post(ApiConstants.LOGIN_API_URL, values).then(result => {
            const resultObj = result.data;
            const genericError = !resultObj.success;
            const userError = resultObj.meta && _.isEmpty(resultObj.meta.user);

            this.setState({
                loading: false,
                serverError: genericError ? (resultObj.message ? resultObj.message : UiConstants.ERROR_TRY_AGAIN) : '',
                userError: userError ? UiConstants.ACCOUNT_NOT_FOUND : ''
            });

            if (!genericError && !userError) {
                this.continueLogin(resultObj.meta.user)
            }
        }, error => {
            this.props.history.push('/technicaldifficulties');
        });
    }

    continueLogin(user) {
        if (this.handleServiceTransfer(user)) {
            return;
        }

        this.finalizeLogin(user);
    }

    handleServiceTransfer(user) {
        const servicerSoldTo = getServicerSoldTo(user);

        if (!servicerSoldTo) {
            return false;
        }

        if (servicerSoldTo == 'Cooper') {
            window.location.replace(getTextFromContent(this.props.cmsContent, CmsKeys.documentCenterMrcLandingPage));
        } else if (servicerSoldTo == 'RightPath') {
            window.location.replace(getTextFromContent(this.props.cmsContent, CmsKeys.documentCenterRpsLandingPage));
        } else if (servicerSoldTo == 'Other') {
            window.location.replace(getTextFromContent(this.props.cmsContent, CmsKeys.documentCenterOtherServicerLandingPage));
        }

        return true;
    }

    finalizeLogin(user) {
        sessionStorage.loggedIn = true;
        this.props.setUser(user);
        this.props.history.push('/upload');
    }

    getError(error) {
        if (!error) {
            return;
        }

        return (
            <div className="box box--error">
                <p>{error}</p>
            </div>
        );
    }

    onAnnouncementPopupClose() {
        this.setState({showAnnouncementPopup: false});
        sessionStorage.sawAnnouncementPopup = true;
    }

    showAnnouncementPopup() {
        if (!this.props.frontendProps.showAnnouncementPopup) {
            return;
        }

        if (this.state.showAnnouncementPopup && !sessionStorage.sawAnnouncementPopup) {
            return (
                <GeneralModal markup={getTextFromContent(this.props.cmsContent, CmsKeys.documentCenterAnnouncementPopup)}
                              onClose={this.onAnnouncementPopupClose}/>
            )
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }

        return (
            <main className="main-content" id="main-content" role="main">
                {this.showAnnouncementPopup()}

                <Title title={<CmsContent pageName={CmsKeys.documentCenterMainTitle}/>}/>
                <section className="section">
                    <div className="container">
                        {this.getError(this.state.userError)}
                        {this.getError(this.state.serverError)}
                        <div className="row">
                            <div className="col-md-2">&nbsp;</div>
                            <div className="col-md-4 box doc-portal-new-user">
                                <h2 className="heading heading--tertiary" alt="Document-Center-Main-Label-Three">
                                    <CmsContent pageName={CmsKeys.documentCenterMainLabelThree}/>
                                </h2>
                                <br/>
                                <RegistrationForm handleUserAccess={this.handleUserAccess}/>
                            </div>

                            <div className="col-md-1">&nbsp;</div>

                            <div className="col-md-4 box doc-portal-returning-user">
                                <h2 className="heading heading--tertiary" alt="Document-Center-MainLabel-One">
                                    <CmsContent pageName={CmsKeys.documentCenterMainLabelOne}/>
                                </h2>
                                <div className="box--info">
                                    <p className="important--support" alt="Document-Center-Main-Label-Two">
                                        <CmsContent pageName={CmsKeys.documentCenterMainLabelTwo}/>
                                    </p>
                                </div>
                                <br/>
                                <LoginForm handleUserAccess={this.handleUserAccess}/>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </section>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent,
        frontendProps: state.frontendProps
    }
}

export default connect(mapStateToProps, {setUser})(Main);