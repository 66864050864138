import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Main from 'components/Bayview/Main';
import Upload from 'components/Bayview/Upload';
import UploadConfirmation from "components/Bayview/UploadConfirmation";
import TechnicalDifficulties from 'components/Bayview/TechnicalDifficulties';
import Error404 from 'components/Bayview/Error404';

const Body = () => {
    function redirectToUpload(props) {
        return sessionStorage.loggedIn ?
            <Redirect to={{
                pathname: '/upload',
                previousLocation: props.location.pathname
            }}/> : <Main {...props}/>;
    }

    function requireAuthentication(Component, props) {
        return sessionStorage.loggedIn ?
            <Component {...props}/> :
            <Redirect to={{
                pathname: '/',
                previousLocation: props.location.pathname
            }}/>;
    }

    return (
        <Switch>
            <Route exact path='/' render={(props) => redirectToUpload(props)}/>
            <Route exact path='/upload' render={(props) => requireAuthentication(Upload, props)}/>
            <Route exact path='/uploadconfirmation' render={(props) => requireAuthentication(UploadConfirmation, props)}/>
            <Route exact path='/technicaldifficulties' component={TechnicalDifficulties}/>

            <Route path='*' component={Error404} />
        </Switch>
    );
};

export default Body;