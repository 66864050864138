class HeaderNavClass {
    constructor() {
        this.menu = document.querySelector('.main-header__menu');
        this.container = document.body;

        this.enable();
    }

    enable() {
        if (!this.menu) {
            return false;
        }
        this.menu.on('click.header-nav', this.toggle.bind(this));
        //document.on('resize.header-nav', this.close.bind(this));
        window.on('resize.header-nav', this.close.bind(this));
        window.on('orientationchange.header-nav', this.close.bind(this));
    }

    disable() {
        this.menu.off('click.header-nav');
        this.menu.off('resize.header-nav');
    }

    toggle(e = {}) {
        e.preventDefault();
        this.container.classList.toggle('nav-open');
    }

    close(e = {}) {
        this.container.classList.remove('nav-open');
    }
}

export default HeaderNavClass;