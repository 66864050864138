import React from 'react';
import {Link} from "react-router-dom";
import HeaderNavClass from "js/external/components/header-nav";
import ShParentNavBar from 'components/SilverHill/ShParentNavBar';

const ShHeader = () => {
    new HeaderNavClass();

    const getNavBar = () => {
        return sessionStorage.loggedIn ? <ShParentNavBar/> : '';
    }

    return (
        <div>
            <header className="main-header">
                <div className="main-header__wrapper">
                    <div className="container">
                        <div className="main-header__content">
                            <Link to="/" className="main-header__logo"/>

                            {getNavBar()}
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
};

export default ShHeader;