import React from 'react';
import PropTypes from 'prop-types';

const Title = ({title}) => {
    return (
        <section className="marquee">
            <div className="container">
                <h1 className="heading heading--ko">
                    {title}
                </h1>
            </div>
        </section>
    );
};

Title.propTypes = {
    title: PropTypes.object
};

export default Title;