class Modal {
  constructor() {
    this.$triggerEl     = null;
    this.$openModal     = null;
    this.viewportHeight = window.innerHeight;

    document.querySelectorAll('[data-trigger="modal"]').forEach((el) => {
      el.on('click.modal-open',this.open.bind(this));
    });
    document.querySelectorAll('[data-close="modal"]').forEach((el) => {
      el.on('click.modal-close',this.close.bind(this));
    });
  }

  setEscapeKey() {
    document.off('keydown.modal-escape').on('keydown.modal-escape', (e) => {
      if (e.which === 27) {
        e.preventDefault();
        this.close(e);
      }
    })
  }

  removeEscapeKey() {
    document.off('keydown.modal-escape');
  }

  lockFocusCycle($modal) {
    // Capture current scroll position to make sure we return here in focus cycle
    let x = window.scrollX, y = window.scrollY;
    // Prevent infinite cycle loop by removing event first
    document.off('focusin.modal-lock').on('focusin.modal-lock',e => {
      if (document !== e.target &&
          $modal !== e.target &&
          ! $modal.contains(e.target) ) {
        // Focus on the first focusable item
        $modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0].focus();
        window.scrollTo(x, y); // Make sure scroll position is returned to
      }
    });
  }

  unlockFocusCycle() {
    document.off('focusin.modal-lock');
  }

  checkSize() {
    let currentViewportHeight = document.body.getBoundingClientRect().height;
    let $modal                = this.$openModal;
    let modalHeight           = $modal.querySelector('.modal__content').offsetHeight;

    if(modalHeight > currentViewportHeight) {
      $modal.classList.add('modal--float');
    } else {
      $modal.classList.remove('modal--float');
    }
  }

  open(e) {
    e.preventDefault();
    let $trigger     = e.target.closest('[data-trigger="modal"]') || e.target;
    let modalType    = $trigger.dataset.modal;
    let $modal       = document.querySelector('#'+modalType+'-modal');
    let $closeButton = $modal.querySelector('.modal__close-button');
    this.$openModal  = $modal;
    this.$triggerEl  = $trigger;

    $modal.style.display = "block";
    document.body.classList.add('modal--open');
    this.checkSize();
    $closeButton.focus();
    this.lockFocusCycle($modal);
    this.setEscapeKey();
    $modal.setAttribute('aria-modal',true);

    document.on('click.modal-background-close',(e) => {
      if(e.target.classList.contains('modal')) {
        this.close(e);
      }
    });
    window.on('resize.modal-resize',(e) => {
      this.checkSize();
    });
    this.$openModal.on('resize.modal-resize',(e) => {
      this.checkSize();
    });
  }

  close(e) {
    let $trigger = e.target;
    let $modal   = $trigger.closest('.modal');

    document.body.classList.remove('modal--open');

    $modal.style.display = "none";
    this.unlockFocusCycle();
    this.removeEscapeKey();
    this.$triggerEl.focus();
    this.$triggerEl = null;

    $modal.setAttribute('aria-modal',false);

    document.off('click.modal-background-close');
    window.off('resize.modal-resize');
    this.$openModal.off('resize.modal-resize');
    this.$openModal = null;

    $modal.querySelectorAll('input[type="password"]').forEach($input => {
      $input.value = '';
      $input.trigger('focusout');
    });
  }

}

new Modal;