import App from 'components/App';

import 'js/external/common';
import reducers from 'js/reducers/index';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import promise from 'redux-promise';
import * as serviceWorker from 'serviceWorker';

import 'css/index.css';
import 'css/App.css';
import 'css/common.min.css';
import 'css/private.min.css';

// global ES6 promise polyfill for axios to work in IE
require('es6-promise').polyfill();
// global ES6 promise polyfill for redux-form and Object assign to work in IE
require('es6-object-assign').polyfill();

/*
* Main javascript file where we configure redux and render our app
 */

const createStoreWithMiddleware = applyMiddleware(promise)(createStore);

export const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducers);
let store = createStoreWithMiddleware(persistedReducer);
let persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App/>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
