import {Field, reduxForm} from "redux-form";
import React, {Component} from 'react';
import {isNotDigitsOnly} from 'js/utils/formatUtils';

class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    renderField(field) {
        const {meta: {touched, error}} = field;

        return (
            <div className="input-group">
                <input className="input input-group__input" type="text" {...field.input} />
                <label className="input-group__label">{field.label}</label>
                {touched && error ? <span className="input-group__helper error">{error}</span> : ''}
            </div>
        );
    }

    render() {
        // deconstruction - handleSubmit is a prop from redux-form; handleUserAccess is my prop from <Main>
        const {handleSubmit, handleUserAccess} = this.props;

        return (
            <form onSubmit={handleSubmit(handleUserAccess)} id="landing-login-form" className="form" aria-labelledby="landing-login-form-label">
                <fieldset className="fieldset" id="landing-login-fieldset">
                    <Field label="Unique ID" component={this.renderField} name="uniqueId"/>
                    <Field label="Property Zip Code" component={this.renderField} name="propertyZip"/>
                    <div className="xs-mu-10 md-mu-0">
                        <div className="cta cta--stacked cta--left">
                            <button type="submit" className="button">Log In</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.uniqueId) {
        errors.uniqueId = "Unique ID is required"
    }
    if (!values.propertyZip) {
        errors.propertyZip = "Property Zip is required"
    }
    if (isNotDigitsOnly(values.propertyZip)) {
        errors.propertyZip = "Property Zip must be numeric"
    }

    return errors;
}

export default reduxForm({
    validate,
    form: "login",
    destroyOnUnmount: false
})(LoginForm);