import React, {Component} from 'react';
import Title from 'components/Title';
import ApiConstants from 'js/utils/apiConstants';
import axios from 'axios';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from 'lodash';
import Loading from 'components/Loading';
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import {setLoan} from 'js/actions';

class Loans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loans: []
        };
    }

    componentDidMount() {
        this.setState({loading: true});
        let user = this.props.user;
        const url = ApiConstants.BROKER_LOANS_API_URL.replace('{brokerId}', user.brokerId);

        axios.get(url).then(result => {
            this.setState({loans: result.data, loading: false});
        }, (error) => {
            this.props.history.push('/technicaldifficulties');
        });
    }

    getLoanRows(loans) {
        return loans.map(loan =>
            <tr key={`loan-${loan.loanNumber}`}>
                <td>{loan.loanNumber}</td>
                <td>{loan.loanName}</td>
                <td>
                    <button className='button' onClick={this.setLoanAndSendToUploadPage.bind(this, loan)}><CmsContent pageName={CmsKeys.shLoanButton}/></button>
                </td>
            </tr>
        );
    }

    setLoanAndSendToUploadPage(loan) {
        this.props.setLoan(loan);
        this.props.history.push('/upload');
    }

    renderLoans() {
        let loans = this.state.loans;

        if (_.isEmpty(loans)) {
            return <div alt={CmsKeys.shNoLoansFound}><CmsContent pageName={CmsKeys.shNoLoansFound}/></div>;
        }

        return (
            <table className="table xs-mu-20" data-table-standard="">
                <thead className="xs-hidden md-table-head">
                <tr>
                    <th scope="col" alt={CmsKeys.shTableHeaderOne}>
                        <CmsContent pageName={CmsKeys.shTableHeaderOne}/>
                    </th>
                    <th scope="col" alt={CmsKeys.shTableHeaderTwo}>
                        <CmsContent pageName={CmsKeys.shTableHeaderTwo}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {this.getLoanRows(loans)}
                </tbody>
            </table>
        );
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }

        return (
            <main className="main-content" id="main-content" role="main">
                <Title title={<CmsContent pageName={CmsKeys.shDocumentCenterMainTitle}/>}/>
                <section className="section section--shadow">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <br/>
                                <div className="row bls-doc-tutorial bls_small_font" alt={CmsKeys.shLoansNotification}>
                                    <CmsContent pageName={CmsKeys.shLoansNotification}/>
                                </div>
                                <br/>
                            </div>
                            <div className="col-xs-12">
                                {this.renderLoans()}
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </section>
            </main>
        )
    }
};

function mapStateToProps(state) {
    return {
        frontendProps: state.frontendProps,
        user: state.user,
        cmsContent: state.cmsContent
    };
}

Loans.propTypes = {
    frontendProps: PropTypes.object,
    user: PropTypes.object,
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps, {setLoan})(Loans);