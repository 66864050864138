import React from 'react';
import Header from 'components/Bayview/Header';
import Footer from 'components/Bayview/Footer';
import Body from 'components/Bayview/Body';

const BlsApp = () => {
    return (
        <div>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    );
};

export default BlsApp;