import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getToday} from 'js/utils/dateUtils';
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import path from 'path';
import {connect} from 'react-redux';

const ShUploadConfirmation = ({uploadResults}) => {
    const getUploadResultsHtml = () => {
        return uploadResults.map((e, i) =>
            <tr key={`upload-result-${i}`}>
                <td data-column="Upload Date"> {getToday()}</td>
                <td data-column="Document Type"> {e.type}</td>
                <td data-column="File Name"> {path.basename(e.file.name)}</td>
            </tr>
        );
    };

    return (
        <section className="section section--shadow doc_padding_control">
            <div className="marquee">
                <h1 className="heading heading--ko">
                    <CmsContent pageName={CmsKeys.shDocumentCenterConfirmationLabelEleven}/>
                </h1>
            </div>
            <div className="container">
                <br/>

                <div className="text-center">
                    <h1 className="heading heading--tertiary" alt="document-Center-Confirmation-Label-Three">
                        <CmsContent pageName={CmsKeys.shDocumentCenterConfirmationLabelThree}/>
                    </h1>
                    <br/>
                    <Link className="button" to='/upload' alt="document-Center-Confirmation-Label-Four">
                        <CmsContent pageName={CmsKeys.shDocumentCenterConfirmationLabelFour}/>
                    </Link>
                </div>

                <br/>

                <div className="bls-doc-uploads">
                    <table className="table xs-mu-20">
                        <thead className="xs-hidden md-table-head">
                        <tr>
                            <th scope="col" data-th="Upload Date" alt="document-Center-Confirmation-Label-Five">
                                <u>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterConfirmationLabelFive}/>
                                </u></th>
                            <th scope="col" data-th="Type" alt="document-Center-Confirmation-Label-Six">
                                <u>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterConfirmationLabelSix}/>
                                </u>
                            </th>
                            <th scope="col" data-th="File Name" alt="document-Center-Confirmation-Label-Seven">
                                <u>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterConfirmationLabelSeven}/>
                                </u></th>
                        </tr>
                        </thead>
                        <tbody>
                        {getUploadResultsHtml()}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
};

function mapStateToProps(state, props) {
    return {
        uploadResults: props.location.state.uploadResults
    };
}

ShUploadConfirmation.propTypes = {
    uploadResults: PropTypes.array
};

export default connect(mapStateToProps)(ShUploadConfirmation);