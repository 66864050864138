import BlsApp from 'components/Bayview/BlsApp';
import ShApp from 'components/SilverHill/ShApp';
import {persistConfig} from "index";
import {fetchCmsContent, fetchFrontendProperties, resetReduxState} from 'js/actions';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import IdleTimer from 'react-idle-timer';
import {connect} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {purgeStoredState} from "redux-persist";
import axios from 'axios';
import ApiConstants from 'js/utils/apiConstants';

/* NOTE: The time is all represented in milliseconds */

// this is the total time allowed to be idle
const ACCEPTABLE_TIMEOUT_ON_IDLE_IN_MIN = 15;
const ACCEPTABLE_TIMEOUT_ON_IDLE_IN_MILLIS = ACCEPTABLE_TIMEOUT_ON_IDLE_IN_MIN * 58 * 1000;

// this is the time that will trigger the onIdle state after being active
const TIMEOUT = 10;
const TIMEOUT_IN_MILLIS = TIMEOUT * 60 * 1000;

const CHILD_APP_COMPONENTS = {
    'bls': BlsApp,
    'sh': ShApp
}

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.idleTimer = null;

        this.onError = this.onError.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.onActive = this.onActive.bind(this);
        this.onIdle = this.onIdle.bind(this);
    }

    componentDidMount() {
        this.props.fetchCmsContent(this.onError);
        this.props.fetchFrontendProperties(this.onError);
        this.setHeadContent();
    }

    setHeadContent() {
        if (!this.props.frontendProps) {
            return;
        }

        this.setFavicon();
    }

    setFavicon() {
        let favicon = document.getElementById('favicon');
        favicon.href = `${this.props.frontendProps.webAppDistro}_favicon.ico`;
    }

    onError() {
        this.handleLogout();
    }

    onActive() {
        if (sessionStorage.loggedIn && !App.isIdleTimeAcceptable(this.idleTimer.getLastActiveTime())) {
            this.handleLogout();
        }
    }

    static isIdleTimeAcceptable(elapsed) {
        const now = moment(new Date());
        const lastActiveTime = moment(elapsed);
        return now.diff(lastActiveTime) < ACCEPTABLE_TIMEOUT_ON_IDLE_IN_MILLIS;
    }

    onIdle() {
        // do nothing
    }

    handleLogout() {
        sessionStorage.clear();
        purgeStoredState(persistConfig);
        axios.delete(ApiConstants.LOGOUT_API_URL);

        window.history.replaceState(null, null, '/');
        window.location = '/';

        this.props.resetReduxState();
    }

    renderDistroCss() {
        if (!this.props.frontendProps) {
            return;
        }

        const distroCss = "css/" + this.props.frontendProps.webAppDistro + "_support.css";
        return <link rel="stylesheet" type="text/css" href={distroCss}/>;
    }

    renderDistroApp() {
        if (!this.props.frontendProps) {
            return;
        }

        const ChildApp = CHILD_APP_COMPONENTS[this.props.frontendProps.webAppDistro];
        return <ChildApp/>;
    }

    render() {
        return (
            <div>
                {this.renderDistroCss()}
                <Router>
                    <IdleTimer
                        ref={ref => {
                            this.idleTimer = ref;
                        }}
                        onActive={this.onActive} // trigger after any action onIdle
                        timeout={TIMEOUT_IN_MILLIS} // time to wait before triggering onIdle
                        onIdle={this.onIdle} // triggers after the user spends TIMEOUT without any action
                    >
                        {this.renderDistroApp()}
                    </IdleTimer>
                </Router>
            </div>
        );
    }
}

App.propTypes = {
    fetchCmsContent: PropTypes.func,
    fetchFrontendProperties: PropTypes.func
};

function mapStateToProps(state) {
    return {frontendProps: state.frontendProps}
}

export default connect(mapStateToProps, {fetchCmsContent, fetchFrontendProperties, resetReduxState})(App);