import Announcer from './announcer';

class ShowHide {
    constructor() {
        this.announcer = new Announcer();
        document.querySelectorAll('[data-hide]').forEach((el) => {
            el.on((el.nodeName === 'INPUT' ? 'focus' : 'click'), this.hide);
        });
        document.querySelectorAll('[data-show]').forEach((el) => {
            el.on((el.nodeName === 'INPUT' ? 'focus' : 'click'), this.show.bind(this));
        });
        document.querySelectorAll('[data-toggle^="#"]').forEach((el) => {
            el.on('click', this.toggle);
        });
        document.querySelectorAll('[data-filter]').forEach((el) => {
            el.on('change', this.select.bind(this));
        });
    }

    show(e) {
        let selector = (typeof e === "object") ? (e.target.dataset.show) : e;
        let focus = (typeof e === "object") ? (e.target.dataset.focus) : null;
        let $targets = document.querySelectorAll(selector);
        let announce = (typeof e === "object") ? (e.target.dataset.announce) : null;

        if (typeof e === "object") {
            if (e.target.tagName.toLowerCase() === 'a') {
                e.preventDefault();
            }
        }

        $targets.forEach(($target) => {
            if ($target.hasAttribute('data-expand')) {
                $target.removeAttribute('data-expand');
                let rowId = $target.closest('tr').id;
                $target.setAttribute('data-collapse', rowId);
            } else {
                $target.classList.remove('hidden');
            }

            $target.setAttribute('aria-hidden', 'false');
            if (announce) {
                this.announcer.say($target.textContent);
            }
        });

        if (focus) {
            $targets[0].setAttribute('tabindex', '-1');
            $targets[0].focus();
        }

    }

    hide(e) {
        let selector = (typeof e === "object") ? (e.target.dataset.hide) : e;
        let disableSelector = (typeof e === "object") ? (e.target.dataset.disable) : null;
        let $targets = null;

        if (selector === 'self') {
            e.target.classList.add('hidden');
            e.target.setAttribute('aria-hidden', 'true');
        } else {
            $targets = document.querySelectorAll(selector);
            $targets.forEach(($target) => {
                if ($target.hasAttribute('data-collapse')) {
                    $target.removeAttribute('data-collapse');
                    let rowId = $target.closest('tr').id;
                    $target.setAttribute('data-expand', rowId);
                } else {
                    $target.classList.add('hidden');
                    $target.setAttribute('aria-hidden', 'true');
                }
            });
        }

        if (disableSelector) {
            let $disabledTargets = document.querySelectorAll(disableSelector);
            $disabledTargets.forEach(($target) => {
                $target.setAttribute("disabled", "disabled");
            });
        }
    }

    toggle(e) {
        let id = e.target.dataset.toggle;
        let $target = document.querySelector(id);

        if ($target.classList.contains('hidden')) {
            $target.classList.remove('hidden');

        } else {
            $target.classList.add('hidden');
        }
    }

    select(e) {
        let id = e.target.id;
        let $children = document.querySelectorAll('[data-controlledby="#' + id + '"]');

        $children.forEach(($el) => {
            this.hide("#" + $el.id);
        });

        let $option = e.target.options[e.target.selectedIndex];
        if ($option.dataset.show.length > 0) {
            this.show($option.dataset.show);
        }
    }
}

export default ShowHide;