class Tabnav {
  constructor() {
    this.$nav = document.querySelector('.tabnav');
    // Bail if no nav was found
    if( ! this.$nav ) { return; }

    this.$tabs = this.$nav.querySelectorAll('.tabnav__tab');

    this.buildMobileMenu();
  }

  /**
   * Uses the tab structure to create a mobile select menu. The
   * correct menu is shown/hidden using the CSS component.
   */
  buildMobileMenu() {
    this.$selectContainer = document.createElement('div');
    this.$selectContainer.classList.add('tabnav__select');

    // Build a select that triggers navigation when it is changed
    this.$select = document.createElement('select');
    this.$select.id = "mobileNavMenu";
    this.$select.classList.add('tabnav__select-node');
    this.$selectLabel = document.createElement('label');
    this.$selectLabel.classList.add('sr-only');
    this.$selectLabel.htmlFor = "mobileNavMenu";
    this.$selectLabel.textContent = "Mobile Navigation Menu";
    this.$select.addEventListener('change',this.navigate.bind(this));

    // Using the text from each tab, add a menu option
    this.$tabs.forEach(($el) => {
      let $opt = document.createElement('option');
      if($el.querySelector('.sr-only')) {
        $opt.textContent = $el.textContent.split($el.querySelector('.sr-only').textContent)[1];
      } else {
        $opt.textContent = $el.textContent;
      }

      if( $el.classList.contains('tabnav__tab--active') ) {
        $opt.selected = true;
      }
      this.$select.appendChild($opt);
    });

    this.$selectContainer.appendChild(this.$select);
    this.$selectContainer.appendChild(this.$selectLabel);
    this.$nav.appendChild(this.$selectContainer);
  }

  /**
   * Triggers navigation by taking the selected index
   * of the mobile select and clicking on the corresponding
   * tab link of the same index.
   */
  navigate() {
    this.$tabs[this.$select.selectedIndex].click();
  }
}

new Tabnav;