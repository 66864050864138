import {Field, reduxForm} from "redux-form";
import React, {Component} from 'react';

import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';

import {isNotDigitsOnly} from 'js/utils/formatUtils';

class RegistrationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    renderField(field) {
        const {meta: {touched, error}} = field;

        return (
            <div className="input-group">
                <input className="input input-group__input" type="text" {...field.input} />
                <label className="input-group__label">{field.label}</label>
                {touched && error ? <span className="input-group__helper error">{error}</span> : ''}
            </div>
        );
    }

    render() {
        // deconstruction - handleSubmit is a prop from redux-form; handleUserAccess is my prop from <Main>
        const {handleSubmit, handleUserAccess} = this.props;

        return (
            <form onSubmit={handleSubmit(handleUserAccess)} id="landing-login-form" className="form" aria-labelledby="landing-login-form-label">
                <fieldset className="fieldset" id="landing-login-fieldset">
                    <Field label="Loan Number" component={this.renderField} name="loanId"/>
                    <Field label="Last 4 SSN" component={this.renderField} name="tin"/>
                    <Field label="Property Zip Code" component={this.renderField} name="propertyZip"/>
                    <div className="xs-mu-10 md-mu-0">
                        <div className="cta cta--stacked cta--left">
                            <button type="submit" className="button" alt="document-Center-Registration-Form-Label-One">
                                <CmsContent pageName={CmsKeys.documentCenterRegistrationFormLabelOne}/>
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.loanId) {
        errors.loanId = "Loan Number is required"
    }
    if (isNotDigitsOnly(values.loanId)) {
        errors.loanId = "Loan Number must be numeric"
    }
    if (!values.tin) {
        errors.tin = "Last 4 SSN is required"
    }
    if (values.tin && (values.tin.length != 4 || isNotDigitsOnly(values.tin))) {
        errors.tin = "Last 4 SSN must be 4 digits"
    }
    if (!values.propertyZip) {
        errors.propertyZip = "Property Zip is required"
    }
    if (isNotDigitsOnly(values.propertyZip)) {
        errors.propertyZip = "Property Zip must be numeric"
    }

    return errors;
}

export default reduxForm({
    validate,
    form: "registration",
    destroyOnUnmount: false
})(RegistrationForm);