import React from 'react';
import PropTypes from 'prop-types';
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import {connect} from 'react-redux';
import {getTextFromContent} from 'js/utils/cmsUtils';

const ShError404 = ({props, cmsContent}) => {
    const goBack = () => {
        props.history.goBack();
    };

    return (
        <main className="main-content" id="main-content" role="main">
            <section className="marquee marquee--sm">
                <div className="container">
                    <h1 className="heading heading--ko" alt={CmsKeys.shNotFoundHeaderLabelOne}>
                        <CmsContent pageName={CmsKeys.shNotFoundHeaderLabelOne}/>
                    </h1>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 center-xs">
                            <p alt={CmsKeys.shNotFoundHeaderLabelTwo}>
                                <CmsContent pageName={CmsKeys.shNotFoundHeaderLabelTwo}/>
                            </p>
                            <p>
                                <a href="#" onClick={goBack}
                                   alt={CmsKeys.shNotFoundHeaderLabelThree}>
                                    <CmsContent pageName={CmsKeys.shNotFoundHeaderLabelThree}/>
                                </a>&nbsp;<CmsContent pageName={CmsKeys.shNotFoundHeaderLabelFour}/>&nbsp;
                                <a href={getTextFromContent(cmsContent, CmsKeys.shNotFoundHeaderLinkForLabelFive)}
                                   alt={CmsKeys.shNotFoundHeaderLinkForLabelFive}>
                                    <span alt={CmsKeys.shNotFoundHeaderLabelFive}>
                                        <CmsContent pageName={CmsKeys.shNotFoundHeaderLabelFive}/>
                                    </span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

function mapStateToProps(state, props) {
    return {props, cmsContent: state.cmsContent};
}

ShError404.propTypes = {
    props: PropTypes.object,
    cmsContent: PropTypes.object,
    history: PropTypes.object,
    goBack: PropTypes.func
};

export default connect(mapStateToProps)(ShError404);