export function getServicerSoldTo(user) {
    if (user.servicingSystem != 'M' || !user.serviceRelease) {
        return null;
    }

    if (user.servicerSoldId == 'CLPL050222' || user.servicerSoldId == 'CLPL060122') {
        if (user.servicerSoldName == 'MRC') {
            return 'Cooper';
        } else if (user.servicerSoldName == 'RPS') {
            return 'RightPath';
        } else {
            return 'Other';
        }
    }

    return 'Other';
}