import {combineReducers} from 'redux';
import {RESET_REDUX_STATE} from 'js/actions';
import CmsContentReducer from 'js/reducers/reducer_cms';
import FrontendPropertiesReducer from 'js/reducers/reducer_frontendproperties';
import {reducer as formReducer} from 'redux-form';
import UserReducer from 'js/reducers/reducer_user';
import LoanReducer from 'js/reducers/reducer_loan';

const appReducer = combineReducers({
    cmsContent: CmsContentReducer,
    frontendProps: FrontendPropertiesReducer,
    form: formReducer,
    user: UserReducer,
    loan: LoanReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_REDUX_STATE) {
        // exclude CMS content from being reset
        const {cmsContent} = state;
        state = {cmsContent};
    }

    return appReducer(state, action);
};

export default rootReducer;