import React from 'react';
import {Link} from "react-router-dom";
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import {getTextFromContent} from 'js/utils/cmsUtils';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const ShFooter = ({cmsContent}) => {
    const documentCenterfooterLinkOne = getTextFromContent(cmsContent, CmsKeys.shDocumentCenterfooterLinkOne);
    const documentCenterfooterLinkTwo = getTextFromContent(cmsContent, CmsKeys.shDocumentCenterfooterLinkTwo);
    const documentCenterfooterLinkThree = getTextFromContent(cmsContent, CmsKeys.shDocumentCenterfooterLinkThree);
    const documentCenterfooterLinkFour = getTextFromContent(cmsContent, CmsKeys.shDocumentCenterfooterLinkFour);
    const documentCenterfooterLinkFive = getTextFromContent(cmsContent, CmsKeys.shDocumentCenterfooterLinkFive);
    const documentCenterfooterLinkSix = getTextFromContent(cmsContent, CmsKeys.shDocumentCenterfooterLinkSix);

    const nmlsUrl = getTextFromContent(cmsContent, CmsKeys.shNmlsUrl);

    return (
        <footer className="main-footer">
            <div className="container">
                <div className="main-footer__content">
                    <Link to="/" className="main-footer__logo">

                    </Link>
                    <nav className="main-footer__nav">
                        <ul className="main-footer__list">
                            <li className="main-footer__item" alt={CmsKeys.shDocumentCenterfooterLinkLabelOne}>
                                <a href={documentCenterfooterLinkOne} className="main-footer__link" alt={CmsKeys.shDocumentCenterfooterLinkOne}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterfooterLinkLabelOne}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt={CmsKeys.shDocumentCenterfooterLinkLabelTwo}>
                                <a href={documentCenterfooterLinkTwo} className="main-footer__link" alt={CmsKeys.shDocumentCenterfooterLinkTwo}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterfooterLinkLabelTwo}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt={CmsKeys.shDocumentCenterfooterLinkLabelThree}>
                                <a href={documentCenterfooterLinkThree} className="main-footer__link" alt={CmsKeys.shDocumentCenterfooterLinkThree}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterfooterLinkLabelThree}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt={CmsKeys.shDocumentCenterfooterLinkLabelFour}>
                                <a href={documentCenterfooterLinkFour} className="main-footer__link" alt={CmsKeys.shDocumentCenterfooterLinkFour}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterfooterLinkLabelFour}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt={CmsKeys.shDocumentCenterfooterLinkLabelFive}>
                                <a href={documentCenterfooterLinkFive} className="main-footer__link" alt={CmsKeys.shDocumentCenterfooterLinkFive}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterfooterLinkLabelFive}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt={CmsKeys.shDocumentCenterfooterLinkLabelSix}>
                                <a href={documentCenterfooterLinkSix} className="main-footer__link" alt={CmsKeys.shDocumentCenterfooterLinkSix}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterfooterLinkLabelSix}/>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="main-footer__copyright">
                <div className="container">
                    <p alt={CmsKeys.shNmlsCopyRight}>
                        <CmsContent pageName={CmsKeys.nmlsCopyRight}/>
                        <a href={nmlsUrl} target="_blank" rel="noopener noreferrer" alt={CmsKeys.shNmlsLabelLink}>
                            <CmsContent pageName={CmsKeys.shNmlsLabelLink}/>
                        </a>
                        <CmsContent pageName={CmsKeys.shNmlsCopyAllRightsReserved}/>
                    </p>
                </div>
            </div>
        </footer>
    );
}

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent
    };
}

ShFooter.propTypes = {
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(ShFooter);