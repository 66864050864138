import ApiConstants from 'js/utils/apiConstants';
import axios from 'axios';

export const FETCH_FRONTEND_PROPERTIES = 'fetch_frontend_properties';
export const FETCH_CMS_CONTENT = 'fetch_cms_content';

export const SET_USER = 'set_user';
export const SET_LOAN = 'set_loan';

export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';

export function fetchFrontendProperties(errorCallback) {
    const request = axios.get(ApiConstants.FRONTEND_PROPERTIES_API_URL).then(null, (error) => errorCallback(error));

    return {
        type: FETCH_FRONTEND_PROPERTIES,
        payload: request
    };
}

export function fetchCmsContent(errorCallback) {
    const request = axios.get(ApiConstants.CONTENT_API_URL).then(null, (error) => errorCallback(error));
    return {
        type: FETCH_CMS_CONTENT,
        payload: request
    };
}

export function resetReduxState() {
    return {
        type: RESET_REDUX_STATE,
        payload: undefined
    };
}

export function setUser(user) {
    return {
        type: SET_USER,
        payload: user
    };
}

export function setLoan(loan) {
    return {
        type: SET_LOAN,
        payload: loan
    };
}