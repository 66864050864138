import React from 'react';

const Loading = () => {
    return (
        <main className="main-content" id="main-content" role="main">
            <div className="loading_overlay">
                <div className="loading_center">
                    <div className="LoaderBalls">
                        <div className="LoaderBalls__item"></div>
                        <div className="LoaderBalls__item"></div>
                        <div className="LoaderBalls__item"></div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Loading;