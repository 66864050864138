import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import ShMain from 'components/SilverHill/ShMain';
import ShUpload from 'components/SilverHill/ShUpload';
import Loans from 'components/SilverHill/Loans';
import ShTechnicalDifficulties from 'components/SilverHill/ShTechnicalDifficulties';
import ShError404 from 'components/SilverHill/ShError404';
import ShUploadConfirmation from 'components/SilverHill/ShUploadConfirmation';
import ShLoanInformation from 'components/SilverHill/ShLoanInformation';

const ShBody = () => {
    function redirectToLoans(props) {
        return sessionStorage.loggedIn ?
            <Redirect to={{
                pathname: '/loans',
                previousLocation: props.location.pathname
            }}/> : <ShMain {...props}/>;
    }

    function requireAuthentication(Component, props) {
        return sessionStorage.loggedIn ?
            <Component {...props}/> :
            <Redirect to={{
                pathname: '/',
                previousLocation: props.location.pathname
            }}/>;
    }

    return (
        <Switch>
            <Route exact path='/' render={(props) => redirectToLoans(props)}/>
            <Route exact path='/loans' render={(props) => requireAuthentication(Loans, props)}/>
            <Route exact path='/upload' render={(props) => requireAuthentication(ShUpload, props)}/>
            <Route exact path='/uploadconfirmation' render={(props) => requireAuthentication(ShUploadConfirmation, props)}/>
            <Route exact path='/loaninformation' render={(props) => requireAuthentication(ShLoanInformation, props)}/>
            <Route exact path='/technicaldifficulties' component={ShTechnicalDifficulties}/>

            <Route path='*' component={ShError404} />
        </Switch>
    );
};

export default ShBody;