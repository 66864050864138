import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Title from 'components/Title';
import ApiConstants from 'js/utils/apiConstants';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from 'lodash';
import {postFiles} from "js/utils/httpUtils";
import {getToday} from 'js/utils/dateUtils';
import Loading from 'components/Loading';

import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import {getTextFromContent} from 'js/utils/cmsUtils';
import mime from 'mime-types';
import RenderHtml from 'components/RenderHtml';
import {buildFilesFormData} from 'js/utils/webUtils';
import UiConstants from 'js/utils/uiConstants';
import ShUploadHistory from 'components/SilverHill/ShUploadHistory';

const ONE_MB_IN_BYTES = 2 ** 20;
const MB = 'MB';

class ShUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            docTypes: this.props.frontendProps.documentTypes.split(','),
            files: [],
            serverError: '',
            validationError: ShUpload.getNewValidationError(),
            fileCounter: 0,
            isSubmitButtonDisabled: true,
            showModal: false
        };

        this.shDocumentCenterUploadNotification = getTextFromContent(this.props.cmsContent, CmsKeys.shDocumentCenterUploadNotification);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.triggerBrowseFile = this.triggerBrowseFile.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);
        this.missingDocType = this.missingDocType.bind(this);
        this.getButtonValue = this.getButtonValue.bind(this);
        this.cancelConfirmation = this.cancelConfirmation.bind(this);
        this.showModalConfirmation = this.showModalConfirmation.bind(this);
        this.uploadProcess = this.uploadProcess.bind(this);
    }

    static getNewValidationError() {
        return {
            docType: {
                errorMessages: []
            },
            files: {
                errorMessages: []
            }
        }
    }

    handleSubmit() {
        this.setState({showModal: true});
    }

    uploadProcess() {
        const files = this.state.files;
        const loan = this.props.loan;

        if (_.isEmpty(files) || this.validate()) {
            return;
        }

        this.setState({loading: true});

        let formData = buildFilesFormData(files);
        formData.append('loanNumber', loan.loanNumber);
        formData.append('loanName', loan.loanName);

        postFiles(ApiConstants.DOCUMENT_UPLOAD_API_URL, formData).then(result => {
            let resultObj = result.data;
            let genericError = !resultObj.success;

            this.setState({
                loading: false,
                showModal: false,
                serverError: genericError ? (resultObj.message ? resultObj.message : UiConstants.ERROR_TRY_AGAIN) : '',
                validationError: ShUpload.getNewValidationError()
            });

            if (!genericError) {
                this.props.history.push({
                    pathname: '/uploadconfirmation',
                    state: {uploadResults: files}
                });
            }
        }, (error) => {
            this.props.history.push('/technicaldifficulties');
        });
    }

    validate() {
        let validationError = ShUpload.getNewValidationError();
        let validate = this.validateDocType(validationError);

        const totalUploadedSize = _.sumBy(this.state.files, (e) => {
            return e.file.size;
        });

        if (this.isFileSizeNotAllowed(totalUploadedSize)) {
            validationError.files.errorMessages.push('Total file size is too large.');
            validate = true;
        }

        _.each(this.state.files, e => {
            if (e.file.name.length > this.props.frontendProps.maxFilenameLength) {
                validationError.files.errorMessages.push(`Filename is too long: ${e.file.name}`);
                validate = true;
            }
        });

        if (validate) {
            this.setState({validationError: validationError, showModal: false});
        }

        return validate;
    }

    validateDocType(validationError) {
        if (this.missingDocType()) {
            validationError.docType.errorMessages.push('A document type is required per file.');
            return true;
        }
        return false;
    }

    missingDocType() {
        return !_.every(this.state.files, 'type');
    }

    isFileSizeNotAllowed(fileSize) {
        return fileSize > this.props.frontendProps.maxUploadSizeInBytes;
    }

    isFileTypeNotAllowed(fileName) {
        const fileType = mime.lookup(fileName);
        return !_.includes(this.props.frontendProps.allowedMimeTypes, fileType);
    }

    calculateMaxFileSizeInMB() {
        const sizeInMegaBytes = this.props.frontendProps.maxUploadSizeInBytes / ONE_MB_IN_BYTES;
        return sizeInMegaBytes + MB;
    }

    handleAddFile(event) {
        const file = event.target.files[0];
        // this is when the user clicks cancel on the browse
        if (!file) {
            return;
        }

        if (this.isFileSizeNotAllowed(file.size)) {
            this.setFilesValidationError('Individual file size is too large. Maximum file size is ' + this.calculateMaxFileSizeInMB());
            return;
        }

        if (this.isFileTypeNotAllowed(file.name)) {
            this.setFilesValidationError('Unsupported file type!');
            return;
        }

        const fileCounter = ++this.state.fileCounter;
        this.state.files.push({id: fileCounter, file: event.target.files[0], type: ''});
        this.setState({files: this.state.files, fileCounter: fileCounter});
        this.clearFileDOMValue();
    }

    setFilesValidationError(message) {
        let validationError = ShUpload.getNewValidationError();
        validationError.files.errorMessages.push(message);
        this.setState({validationError});
        this.clearFileDOMValue();
    }

    clearFileDOMValue() {
        // Google Chrome needs to detect a change in the DOM to trigger the onChange event
        ReactDOM.findDOMNode(this.refs.file).value = null;
    }

    triggerBrowseFile(event) {
        event.preventDefault();

        // this is when the user try to select a file without setting the type for previous file
        if (this.refs.file.disabled) {
            let validationError = ShUpload.getNewValidationError();
            if (this.validateDocType(validationError)) {
                this.setState({validationError: validationError});
            }
            return;
        } else {
            this.setState({validationError: ShUpload.getNewValidationError()});
        }

        ReactDOM.findDOMNode(this.refs.file).click();
    }

    handleSelectDocType(id, event) {
        const value = event.target.value;
        let file = this.getFile(id);
        file.type = value;
        this.setState({files: this.state.files, isSubmitButtonDisabled: this.isSubmitButtonDisabled()});
    }

    isSubmitButtonDisabled() {
        return _.isEmpty(this.state.files) || this.missingDocType();
    }

    getFilesHtml() {
        if (_.isEmpty(this.state.files)) {
            return (
                <tr>
                    <td colSpan="5">
                        <div className="bls-notification bls-content-center">
                            <h1 alt="document-Center-Upload-Label-One">
                                <CmsContent pageName={CmsKeys.documentCenterUploadLabelOne}/>
                            </h1>
                        </div>
                    </td>
                </tr>
            );
        }

        let html = this.state.files.map(e =>
            <tr key={`file-${e.id}`}>
                <th scope="row">
                    <CmsContent pageName={CmsKeys.documentCenterUploadLabelSeventeen}/>
                </th>
                <td>{getToday()}</td>
                <th scope="row">
                    <CmsContent pageName={CmsKeys.documentCenterUploadLabelEighteen}/>
                </th>
                <td>
                    <form>
                        <div>
                            <select className="bls-select" onChange={this.handleSelectDocType.bind(this, e.id)}
                                    value={this.getSelectedDocTypeValue(e.id)}>
                                <option value="">
                                    {getTextFromContent(this.props.cmsContent, CmsKeys.shDocumentCenterUploadLabelNineteen)}
                                </option>
                                {this.state.docTypes.map((e, i) =>
                                    <option key={`doc-type-${i}`} value={e}>{e}</option>
                                )}
                            </select>
                            {this.renderDocTypeValidationError(e.id)}
                        </div>
                    </form>
                </td>
                <th scope="row" alt="documentCenterUploadLabelTwo">
                    <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelTwo}/>
                </th>
                <td>{e.file.name}</td>
                <th scope="row" className="float-right" alt="document-Center-Upload-Label-Three">
                    <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelThree}/>
                </th>
                <td>
                    <button onClick={this.removeFile.bind(this, e.id)} alt="document-Center-Upload-Label-Four">
                        <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelFour}/></button>
                </td>
            </tr>
        );

        return html;
    }

    getFile(id) {
        return _.find(this.state.files, e => {
            return e.id == id;
        });
    }

    getSelectedDocTypeValue(id) {
        let file = this.getFile(id);

        return file ? file.type : '';
    }

    getButtonValue() {
        return this.state.isSubmitButtonDisabled ?
            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelTwenty}/> :
            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelSixteen}/>;
    }

    isDocTypeSelected(id) {
        return this.getSelectedDocTypeValue(id);
    }

    removeFile(id) {
        _.remove(this.state.files, e => {
            return e.id == id;
        });

        this.setState({
            files: this.state.files,
            serverError: '',
            isSubmitButtonDisabled: this.isSubmitButtonDisabled()
        });
    }

    getAddDocumentsButton() {
        if (_.isEmpty(this.state.files)) {
            return;
        }

        return (
            <tr>
                <td colSpan="4">
                    <a className="button button--hollow bls-vertical float-right"
                       alt="document-Center-Upload-Label-Five" onClick={this.triggerBrowseFile}>
                        <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelFive}/>
                    </a>
                    <br/>
                    <br/>
                    <br/>
                </td>
            </tr>
        );
    }

    getServerError() {
        if (!this.state.serverError) {
            return;
        }

        return (
            <div className="box box--error">
                <p>{this.state.serverError}</p>
            </div>
        );
    }

    cancelConfirmation() {
        this.setState({showModal: false});
    }

    getConfirmationMessageContent() {
        let loan = this.props.loan;

        return getTextFromContent(this.props.cmsContent, CmsKeys.shDocumentCenterUploadConfirmationMessage)
            .replace('{loanNumber}', loan.loanNumber)
            .replace('{loanName}', loan.loanName);
    }

    showModalConfirmation() {
        if (this.state.showModal) {
            return (
                <div className="overlay">
                    <div className="popup">
                        <div className="content">
                            <h2><CmsContent pageName={CmsKeys.shDocumentCenterUploadConfirmationTitle}/></h2>
                            <hr/>
                            <p>{this.getConfirmationMessageContent()}</p>
                            <hr/>
                            <div className="cta cta--form">
                                <a href="#" onClick={this.uploadProcess} className="button">Yes</a>
                                <a href="#" onClick={this.cancelConfirmation}>No</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderDocTypeValidationError(id) {
        if (!_.isEmpty(this.state.validationError.docType.errorMessages) && !this.isDocTypeSelected(id)) {
            return (
                <span className="input-group__helper error" alt="document-Center-Upload-Label-Six">
                <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelSix}/> 
                </span>
            );
        }
    }

    renderValidationError(name) {
        if (!_.isEmpty(this.state.validationError[name].errorMessages)) {
            return (
                this.state.validationError[name].errorMessages.map((e, i) =>
                    <span key={i} className="input-group__helper error">{e}</span>
                )
            );
        }
    }

    renderLoanInfo() {
        let loan = this.props.loan;

        if (!loan) {
            return;
        }

        return (
            <b>
                {loan.loanNumber} &nbsp; {loan.loanName}
            </b>
        );
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }

        return (
            <main className="main-content" id="main-content" role="main">
                <Title title={<CmsContent pageName={CmsKeys.shDocumentCenterMainTitle}/>}/>
                <section className="section section--shadow">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <br/>
                                <div className="row bls-doc-tutorial bls_small_font">
                                    <RenderHtml altTagInfo={CmsKeys.shDocumentCenterUploadNotification} htmlContent={this.shDocumentCenterUploadNotification}/>
                                </div>
                                <br/>
                                <div className="row bls-doc-tutorial upload">
                                    <div className="col-md-9">
                                        <h3 className="heading heading--secondary"
                                            alt="document-Center-Upload-Label-Seven">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelSeven}/>
                                        </h3>
                                        <p alt="document-Center-Upload-Label-Eight">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelEight}/>
                                        </p>
                                        <hr/>
                                        {this.renderLoanInfo()}
                                        <em className="bls-notification" alt="document-Center-Upload-Label-Nine">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelNine}/>
                                        </em>
                                    </div>
                                    <div className="col-md-3 bls-content-center">
                                        <br/>
                                        <button alt="document-Center-Upload-Label-Ten"
                                                className="button button--hollow bls-vertical float-right"
                                                onClick={this.triggerBrowseFile}>
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelTen}/>
                                        </button>
                                        <input type="file" name="files" className="hidden" ref="file"
                                               id="browse-files"
                                               alt="add file"
                                               disabled={this.missingDocType()}
                                               onChange={this.handleAddFile}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <table className="table xs-mu-20" data-table-standard="">
                                    <thead className="xs-hidden md-table-head">
                                    <tr>
                                        <th scope="col" alt="document-Center-Upload-Label-Eleven">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelEleven}/>
                                        </th>
                                        <th scope="col" alt="document-Center-Upload-Label-Twelve">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelTwelve}/>
                                        </th>
                                        <th scope="col" alt="document-Center-Upload-Label-Thirteen">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelThirteen}/>
                                        </th>
                                        <th scope="col" alt="document-Center-Upload-Label-Fourteen">
                                            <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelFourteen}/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getFilesHtml()}
                                    {this.getAddDocumentsButton()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br/>
                        <br/>

                        {this.renderValidationError('files')}
                        {this.getServerError()}

                        <br/>
                        <div className="row bls-doc-tutorial">
                            <br/>
                            <div className="col-md-8 bold-font" alt={CmsKeys.shDocumentCenterUploadLabelFifteen}>
                                <CmsContent pageName={CmsKeys.shDocumentCenterUploadLabelFifteen}/>
                            </div>
                            <div className="col-md-4">
                                <button
                                    className={`${this.state.isSubmitButtonDisabled ? 'button_inactive' : 'button'} float-right`}
                                    disabled={this.state.isSubmitButtonDisabled}
                                    onClick={this.handleSubmit}
                                    alt={`${CmsKeys.shDocumentCenterUploadLabelSixteen} or ${CmsKeys.shDocumentCenterUploadLabelTwenty}`}>
                                    {this.getButtonValue()}
                                </button>
                            </div>
                        </div>
                        <br/>

                        {this.showModalConfirmation()}

                        <ShUploadHistory {...this.props}/>
                    </div>
                </section>
            </main>
        )
    }
};

function mapStateToProps(state) {
    return {
        frontendProps: state.frontendProps,
        cmsContent: state.cmsContent,
        loan: state.loan
    };
}

ShUpload.propTypes = {
    frontendProps: PropTypes.object,
    cmsContent: PropTypes.object,
    loan: PropTypes.object
};

export default connect(mapStateToProps)(ShUpload);