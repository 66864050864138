import React from 'react';
import {Link} from "react-router-dom";
import HeaderNavClass from "js/external/components/header-nav";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTextFromContent} from 'js/utils/cmsUtils';
import CmsKeys from 'js/utils/cmsKeys';

const Header = ({cmsContent}) => {
    new HeaderNavClass();
    const documentCenterHeaderLogoImageUrl = getTextFromContent(cmsContent, CmsKeys.documentCenterHeaderLogoImageUrl);

    return (
        <div>
            <header className="main-header">
                <div className="main-header__wrapper">
                    <div className="container">
                        <div className="main-header__content">
                            <Link to="/" className="main-header__logo">
                                <img src={documentCenterHeaderLogoImageUrl} alt="Logo"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
};

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent
    }
}

Header.propTypes = {
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(Header);