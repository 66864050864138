import React from 'react';
import ShHeader from 'components/SilverHill/ShHeader';
import ShFooter from 'components/SilverHill/ShFooter';
import ShBody from 'components/SilverHill/ShBody';


const ShApp = () => {
    return (
        <div>
            <ShHeader/>
            <ShBody/>
            <ShFooter/>
        </div>
    );
};

export default ShApp;