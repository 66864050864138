import React from 'react';
import PropTypes from 'prop-types';
import CmsKeys from 'js/utils/cmsKeys';
import CmsContent from 'components/CmsContent';
import {getTextFromContent} from 'js/utils/cmsUtils';
import {connect} from 'react-redux';

const ShTechnicalDifficulties = ({cmsContent}) => {
    return (
        <section className="section section--shadow">
            <div className="container">
                <h1 className="heading heading--secondary">
                    <CmsContent pageName={CmsKeys.shTechnicalDifficultiesPageTitle}/>
                </h1>
                <div className="row">
                    <div className="col-xs-12 col-md-10 col-md-offset-1 text-center" alt={CmsKeys.shTechnicalDifficultiesPageMessage}
                        dangerouslySetInnerHTML={{__html: getTextFromContent(cmsContent, CmsKeys.shTechnicalDifficultiesPageMessage)}}>
                    </div>
                </div>
            </div>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent
    };
}

ShTechnicalDifficulties.propTypes = {
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(ShTechnicalDifficulties);