import React from 'react';
import {Link} from "react-router-dom";
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import {getTextFromContent} from 'js/utils/cmsUtils';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const Footer = ({cmsContent}) => {
    const documentCenterfooterLinkOne = getTextFromContent(cmsContent, CmsKeys.documentCenterfooterLinkOne);
    const documentCenterfooterLinkTwo = getTextFromContent(cmsContent, CmsKeys.documentCenterfooterLinkTwo);
    const documentCenterfooterLinkThree = getTextFromContent(cmsContent, CmsKeys.documentCenterfooterLinkThree);
    const documentCenterfooterLinkFour = getTextFromContent(cmsContent, CmsKeys.documentCenterfooterLinkFour);
    const documentCenterfooterLinkFive = getTextFromContent(cmsContent, CmsKeys.documentCenterfooterLinkFive);
    const documentCenterfooterLinkSix = getTextFromContent(cmsContent, CmsKeys.documentCenterfooterLinkSix);
    const documentCenterFooterLogoImageUrl = getTextFromContent(cmsContent, CmsKeys.documentCenterFooterLogoImageUrl);

    const nmlsUrl = getTextFromContent(cmsContent, CmsKeys.nmlsUrl);

    return (
        <footer className="main-footer">
            <div className="container">
                <div className="main-footer__content">
                    <Link to="/" className="main-footer__logo">
                        <img src={documentCenterFooterLogoImageUrl} alt="Logo"/>
                    </Link>
                    <nav className="main-footer__nav">
                        <ul className="main-footer__list">
                            <li className="main-footer__item" alt="Document-Center-footer-Link-Label-One">
                                <a href={documentCenterfooterLinkOne} className="main-footer__link" alt="Document-Center-footer-Link-One">
                                    <CmsContent pageName={CmsKeys.documentCenterfooterLinkLabelOne}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt="Document-Center-footer-Link-Label-Two">
                                <a href={documentCenterfooterLinkTwo} className="main-footer__link" alt="Document-Center-footer-Link-Two">
                                    <CmsContent pageName={CmsKeys.documentCenterfooterLinkLabelTwo}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt="Document-Center-footer-Link-Label-Three">
                                <a href={documentCenterfooterLinkThree} className="main-footer__link" alt="Document-Center-footer-Link-Three">
                                    <CmsContent pageName={CmsKeys.documentCenterfooterLinkLabelThree}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt="Document-Center-footer-Link-Label-Four">
                                <a href={documentCenterfooterLinkFour} className="main-footer__link" alt="Document-Center-footer-Link-Four">
                                    <CmsContent pageName={CmsKeys.documentCenterfooterLinkLabelFour}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt="Document-Center-footer-Link-Label-Five">
                                <a href={documentCenterfooterLinkFive} className="main-footer__link" alt="Document-Center-footer-Link-Five">
                                    <CmsContent pageName={CmsKeys.documentCenterfooterLinkLabelFive}/>
                                </a>
                            </li>
                            <li className="main-footer__item" alt="Document-Center-footer-Link-Label-Six">
                                <a href={documentCenterfooterLinkSix} className="main-footer__link" alt="Document-Center-footer-Link-Six">
                                    <CmsContent pageName={CmsKeys.documentCenterfooterLinkLabelSix}/>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="main-footer__copyright">
                <div className="container">
                    <p alt={CmsKeys.nmlsCopyRight}>
                        <CmsContent pageName={CmsKeys.nmlsCopyRight}/>
                        <a href={nmlsUrl} target="_blank" rel="noopener noreferrer" alt={CmsKeys.nmlsLabelLink}>
                            <CmsContent pageName={CmsKeys.nmlsLabelLink}/>
                        </a>
                        <CmsContent pageName={CmsKeys.nmlsCopyAllRightsReserved}/>
                    </p>
                </div>
            </div>
        </footer>
    );
}

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent
    };
}

Footer.propTypes = {
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(Footer);