class HeaderSearchClass {
  constructor() {
    this.searchField = document.querySelector('.main-header__searchfield');
    
    if( this.enable() ) {
      this.setClosedAttributes();
    }
  }

  enable() {
    if( ! this.searchField ) { return false; }
    
    this.container    = document.querySelector('.main-header');
    this.searchIcon   = document.querySelector('.main-header__search-icon');
    this.searchInput  = document.querySelector('.main-header__search-input');
    this.searchButton = document.querySelector('.main-header__search-button');
    
    this.searchInput.setAttribute('data-focus-lock','search-nav');
    this.searchIcon.setAttribute('data-focus-lock','search-nav');
    this.searchButton.setAttribute('data-focus-lock','search-nav');
    
    this.searchIcon.on('click.search-nav',this.open.bind(this));
    
    return this;
  }

  disable() {
    this.searchIcon.off('click.search-nav');
    return this;
  }

  lockFocusCycle() {
    document.body.on('keydown.search-nav',(e) => {
      let $target = e.target;
      // Tab key, but no shift, forward tab
      if( e.keyCode === 9 && e.shiftKey === false ) {
        // Open/Close button -> move to the input
        if( $target.closest('.main-header__content') ) {
          e.preventDefault();
          this.searchInput.focus();
        // Search button -> move to the open/close icon
        } else if( $target.classList.contains('main-header__search-button') ) {
          e.preventDefault();
          this.searchIcon.focus();
        }
      // Tab key + shift, back tab
      } else if( e.keyCode == 9 && e.shiftKey ) {
        // Open/close button -> move to search button
        if( $target.closest('.main-header__content') ) {
          e.preventDefault();
          this.searchButton.focus();
        // Input -> move to search open/close icon
        } else if( $target.classList.contains('main-header__search-input') ) {
          e.preventDefault();
          this.searchIcon.focus();
        }
      }
    });
  }

  releaseFocusCycleLock() {
    document.body.off('keydown.search-nav');
  }

  onDocumentClose(e) {
    if( ! e.target.closest('.main-header__searchfield') ) {
      this.close();
    }
  }

  setOpenAttributes() {
    this.container.classList.add('main-header--search-open');
    this.searchField.setAttribute('aria-hidden',false);
    this.searchInput.removeAttribute('tabindex');
    this.searchButton.removeAttribute('tabindex');
    this.searchIcon.setAttribute('aria-expanded',true);
  }

  setClosedAttributes() {
    this.container.classList.remove('main-header--search-open');
    this.searchField.setAttribute('aria-hidden',true);
    this.searchIcon.setAttribute('aria-expanded',false);
    this.searchInput.setAttribute('tabindex',-1);
    this.searchButton.setAttribute('tabindex',-1);
  }

  open() {
    this.setOpenAttributes();
    this.lockFocusCycle();
    this.searchIcon.off('click.search-nav');
    this.searchIcon.on('click.search-nav',this.close.bind(this));
    
    // Clear stack by using timeout
    setTimeout(() => document.body.on('click.search-nav',this.onDocumentClose.bind(this)));
  }

  close() {
    this.setClosedAttributes();
    this.releaseFocusCycleLock();
    this.searchIcon.off('click.search-nav');
    this.searchIcon.on('click.search-nav',this.open.bind(this));
    document.body.off('click.search-nav');
  }
}

new HeaderSearchClass();