import axios from 'axios';
import CmsContent from 'components/CmsContent';
import Loading from 'components/Loading';
import ShLoginForm from 'components/SilverHill/ShLoginForm';
import Title from 'components/Title';
import {setUser} from 'js/actions';
import InputFocusClass from 'js/external/components/input-focus';
import ApiConstants from 'js/utils/apiConstants';
import CmsKeys from 'js/utils/cmsKeys';
import UiConstants from 'js/utils/uiConstants';
import _ from 'lodash';
import React, {Component} from 'react';

import {connect} from 'react-redux';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            serverError: '',
            userError: ''
        };

        this.handleUserAccess = this.handleUserAccess.bind(this);
    }

    componentDidMount() {
        new InputFocusClass();
    }

    componentDidUpdate() {
        new InputFocusClass();
    }

    handleUserAccess(values) {
        this.setState({loading: true});
        const url = ApiConstants.BROKER_API_URL.replace('{brokerId}', values.uniqueId);

        axios.get(url).then(result => {
            const resultObj = result.data;
            const userError = _.isEmpty(resultObj) && _.isEmpty(resultObj.brokerId);

            this.setState({
                loading: false,
                userError: userError ? UiConstants.ACCOUNT_NOT_FOUND : ''
            });

            if (!userError) {
                sessionStorage.loggedIn = true;
                this.props.setUser(resultObj);
                this.props.history.push('/loans');
            }
        }, error => {
            this.props.history.push('/technicaldifficulties');
        });
    }

    getError(error) {
        if (!error) {
            return;
        }

        return (
            <div className="box box--error">
                <p>{error}</p>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }

        return (
            <main className="main-content" id="main-content" role="main">
                <Title title={<CmsContent pageName={CmsKeys.shDocumentCenterMainTitle}/>}/>
                <section className="section">
                    <div className="container">
                        {this.getError(this.state.userError)}
                        {this.getError(this.state.serverError)}
                        <div className="row">

                            <div className="col-md-2">&nbsp;</div>
                            <div className="col-md-1">&nbsp;</div>

                            <div className="col-md-4 box">
                                <h2 className="heading heading--tertiary" alt={CmsKeys.shDocumentCenterMainLabelOne}>
                                    <CmsContent pageName={CmsKeys.shDocumentCenterMainLabelOne}/>
                                </h2>
                                <div className="box--info">
                                    <p className="important--support" alt={CmsKeys.shDocumentCenterMainLabelTwo}>
                                        <CmsContent pageName={CmsKeys.shDocumentCenterMainLabelTwo}/>
                                    </p>
                                </div>
                                <br/>
                                <ShLoginForm handleUserAccess={this.handleUserAccess}/>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </section>
            </main>
        )
    }
}


function mapStateToProps(state) {
    return {frontendProps: state.frontendProps}
}

export default connect(mapStateToProps, {setUser})(Main);