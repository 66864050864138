const BASE_API_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8081';

const ApiConstants = {
    /* API URLs */
    CONTENT_API_URL: `${BASE_API_URL}/contents`,
    FRONTEND_PROPERTIES_API_URL: `${BASE_API_URL}/properties`,
    DOCUMENT_UPLOAD_API_URL: `${BASE_API_URL}/uploads`,

    LOGIN_API_URL: `${BASE_API_URL}/users/login`,
    LOGOUT_API_URL: `${BASE_API_URL}/users/logout`,

    BROKER_API_URL: `${BASE_API_URL}/brokers/{brokerId}`,
    BROKER_LOANS_API_URL: `${BASE_API_URL}/brokers/{brokerId}/loans`,
    DOCUMENT_HISTORY_API_URL: `${BASE_API_URL}/loans/{loanNumber}/documents`
};

export default ApiConstants;