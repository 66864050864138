import _ from 'lodash';

export function buildFilesFormData(files) {
    var formData = new FormData();

    _.each(files, e => {
        formData.append('docTypes', e.type);
        formData.append('files', e.file);
    });

    return formData;
}