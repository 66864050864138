import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Title from 'components/Title';
import ApiConstants from 'js/utils/apiConstants';
import UiConstants from 'js/utils/uiConstants';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from 'lodash';
import {postFiles} from "js/utils/httpUtils";
import {getToday} from 'js/utils/dateUtils';
import Loading from 'components/Loading';

import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import {getTextFromContent} from 'js/utils/cmsUtils';
import mime from 'mime-types';
import RenderHtml from 'components/RenderHtml';
import {buildFilesFormData} from 'js/utils/webUtils';

const ONE_MB_IN_BYTES = 2 ** 20;
const MB = 'MB';

class Upload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            docTypes: this.props.frontendProps.documentTypes.split(','),
            files: [],
            serverError: '',
            validationError: Upload.getNewValidationError(),
            fileCounter: 0,
            isSubmitButtonDisabled: true
        };

        this.documentCenterUploadNotification = getTextFromContent(this.props.cmsContent, CmsKeys.documentCenterUploadNotification);
        

        this.handleSubmit = this.handleSubmit.bind(this);
        this.triggerBrowseFile = this.triggerBrowseFile.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);
        this.missingDocType = this.missingDocType.bind(this);
        this.getButtonValue = this.getButtonValue.bind(this);
    }

    static getNewValidationError() {
        return {
            docType: {
                errorMessages: []
            },
            files: {
                errorMessages: []
            }
        }
    }

    handleSubmit() {
        if (_.isEmpty(this.state.files) || this.validate()) {
            return;
        }

        this.setState({loading: true});

        const user = this.props.user;
        let formData = buildFilesFormData(this.state.files);
        formData.append('userJson', JSON.stringify(user));
        formData.append('loanNumber', user.loanId);

        postFiles(ApiConstants.DOCUMENT_UPLOAD_API_URL, formData).then(result => {
            let resultObj = result.data;
            let genericError = !resultObj.success;

            this.setState({
                loading: false,
                serverError: genericError ? (resultObj.message ? resultObj.message : UiConstants.ERROR_TRY_AGAIN) : '',
                validationError: Upload.getNewValidationError()
            });

            if (!genericError) {
                this.props.history.push({
                    pathname: '/uploadconfirmation',
                    state: {email: this.props.user.email, uploadResults: resultObj.meta.typedDocuments}
                });
            }
        }, (error) => {
            this.props.history.push('/technicaldifficulties');
        });
    }

    validate() {
        let validationError = Upload.getNewValidationError();
        let validate = this.validateDocType(validationError);

        const totalUploadedSize = _.sumBy(this.state.files, (e) => {
            return e.file.size;
        });

        if (this.isFileSizeNotAllowed(totalUploadedSize)) {
            validationError.files.errorMessages.push('Total file size is too large.');
            validate = true;
        }

        _.each(this.state.files, e => {
            if (e.file.name.length > this.props.frontendProps.maxFilenameLength) {
                validationError.files.errorMessages.push(`Filename is too long: ${e.file.name}`);
                validate = true;
            }
        });

        if (validate) {
            this.setState({validationError: validationError});
        }

        return validate;
    }

    validateDocType(validationError) {
        if (this.missingDocType()) {
            validationError.docType.errorMessages.push('A document type is required per file.');
            return true;
        }
        return false;
    }

    missingDocType() {
        return !_.every(this.state.files, 'type');
    }

    isFileSizeNotAllowed(fileSize) {
        return fileSize > this.props.frontendProps.maxUploadSizeInBytes;
    }

    isFileTypeNotAllowed(fileName) {
        const fileType = mime.lookup(fileName);
        return !_.includes(this.props.frontendProps.allowedMimeTypes, fileType);
    }

    calculateMaxFileSizeInMB() {
        const sizeInMegaBytes = this.props.frontendProps.maxUploadSizeInBytes / ONE_MB_IN_BYTES;
        return sizeInMegaBytes + MB;
    }

    handleAddFile(event) {
        const file = event.target.files[0];
        // this is when the user clicks cancel on the browse
        if (!file) {
            return;
        }

        if (this.isFileSizeNotAllowed(file.size)) {
            this.setFilesValidationError('Individual file size is too large. Maximum file size is ' + this.calculateMaxFileSizeInMB());
            return;
        }

        if (this.isFileTypeNotAllowed(file.name)) {
            this.setFilesValidationError('Unsupported file type!');
            return;
        }

        const fileCounter = ++this.state.fileCounter;
        this.state.files.push({id: fileCounter, file: event.target.files[0], type: ''});
        this.setState({files: this.state.files, fileCounter: fileCounter});
        this.clearFileDOMValue();
    }

    setFilesValidationError(message) {
        let validationError = Upload.getNewValidationError();
        validationError.files.errorMessages.push(message);
        this.setState({validationError});
        this.clearFileDOMValue();
    }

    clearFileDOMValue() {
        // Google Chrome needs to detect a change in the DOM to trigger the onChange event
        ReactDOM.findDOMNode(this.refs.file).value = null;
    }

    triggerBrowseFile(event) {
        event.preventDefault();

        // this is when the user try to select a file without setting the type for previous file
        if (this.refs.file.disabled) {
            let validationError = Upload.getNewValidationError();
            if (this.validateDocType(validationError)) {
                this.setState({validationError: validationError});
            }
            return;
        } else {
            this.setState({validationError: Upload.getNewValidationError()});
        }

        ReactDOM.findDOMNode(this.refs.file).click();
    }

    handleSelectDocType(id, event) {
        const value = event.target.value;
        let file = this.getFile(id);
        file.type = value;
        this.setState({files: this.state.files, isSubmitButtonDisabled: this.isSubmitButtonDisabled()});
    }

    isSubmitButtonDisabled() {
        return _.isEmpty(this.state.files) || this.missingDocType();
    }

    getFilesHtml() {
        if (_.isEmpty(this.state.files)) {
            return (
                <tr>
                    <td colSpan="5">
                        <div className="bls-notification bls-content-center">
                            <h1 alt="document-Center-Upload-Label-One">
                                <CmsContent pageName={CmsKeys.documentCenterUploadLabelOne}/>
                            </h1>
                        </div>
                    </td>
                </tr>
            );
        }

        let html = this.state.files.map(e =>
            <tr key={`file-${e.id}`}>
                <th scope="row">
                    <CmsContent pageName={CmsKeys.documentCenterUploadLabelSeventeen}/>
                </th>
                <td>{getToday()}</td>
                <th scope="row">
                    <CmsContent pageName={CmsKeys.documentCenterUploadLabelEighteen}/>
                </th>
                <td>
                    <form>
                        <div>
                            <select className="bls-select" onChange={this.handleSelectDocType.bind(this, e.id)}
                                    value={this.getSelectedDocTypeValue(e.id)}>
                                <option value="">
                                    {getTextFromContent(this.props.cmsContent, CmsKeys.documentCenterUploadLabelNineteen)}
                                </option>
                                {this.state.docTypes.map((e, i) =>
                                    <option key={`doc-type-${i}`} value={e}>{e}</option>
                                )}
                            </select>
                            {this.renderDocTypeValidationError(e.id)}
                        </div>
                    </form>
                </td>
                <th scope="row" alt="documentCenterUploadLabelTwo">
                    <CmsContent pageName={CmsKeys.documentCenterUploadLabelTwo}/>
                </th>
                <td>{e.file.name}</td>
                <th scope="row" className="float-right" alt="document-Center-Upload-Label-Three">
                    <CmsContent pageName={CmsKeys.documentCenterUploadLabelThree}/>
                </th>
                <td>
                    <button onClick={this.removeFile.bind(this, e.id)} alt="document-Center-Upload-Label-Four">
                        <CmsContent pageName={CmsKeys.documentCenterUploadLabelFour}/></button>
                </td>
            </tr>
        );

        return html;
    }

    getFile(id) {
        return _.find(this.state.files, e => {
            return e.id == id;
        });
    }

    getSelectedDocTypeValue(id) {
        let file = this.getFile(id);

        return file ? file.type : '';
    }

    getButtonValue() {
        return this.state.isSubmitButtonDisabled ?
            <CmsContent pageName={CmsKeys.documentCenterUploadLabelTwenty}/> :
            <CmsContent pageName={CmsKeys.documentCenterUploadLabelSixteen}/>;
    }

    isDocTypeSelected(id) {
        return this.getSelectedDocTypeValue(id);
    }

    removeFile(id) {
        _.remove(this.state.files, e => {
            return e.id == id;
        });

        this.setState({
            files: this.state.files,
            serverError: '',
            isSubmitButtonDisabled: this.isSubmitButtonDisabled()
        });
    }

    getAddDocumentsButton() {
        if (_.isEmpty(this.state.files)) {
            return;
        }

        return (
            <tr>
                <td colSpan="4">
                    <a className="button button--hollow bls-vertical float-right"
                       alt="document-Center-Upload-Label-Five" onClick={this.triggerBrowseFile}>
                        <CmsContent pageName={CmsKeys.documentCenterUploadLabelFive}/>
                    </a>
                    <br/>
                    <br/>
                    <br/>
                </td>
            </tr>
        );
    }

    getServerError() {
        if (!this.state.serverError) {
            return;
        }

        return (
            <div className="box box--error">
                <p>{this.state.serverError}</p>
            </div>
        );
    }

    renderDocTypeValidationError(id) {
        if (!_.isEmpty(this.state.validationError.docType.errorMessages) && !this.isDocTypeSelected(id)) {
            return (
                <span className="input-group__helper upload-error error" alt="document-Center-Upload-Label-Six">
                <CmsContent pageName={CmsKeys.documentCenterUploadLabelSix}/> 
                </span>
            );
        }
    }

    renderValidationError(name) {
        if (!_.isEmpty(this.state.validationError[name].errorMessages)) {
            return (
                this.state.validationError[name].errorMessages.map((e, i) =>
                    <span key={i} className="input-group__helper upload-error error">{e}</span>
                )
            );
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }

        return (
            <main className="main-content" id="main-content" role="main">
                <Title title={<CmsContent pageName={CmsKeys.documentCenterMainTitle}/>}/>
                <section className="section section--shadow">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <br/>
                                <div className="row bls-doc-tutorial bls_small_font">
                                <RenderHtml altTagInfo={"document-Center-Upload-Notification"} htmlContent={this.documentCenterUploadNotification}/>
                                </div>
                                <br />
                                <div className="row bls-doc-tutorial upload">
                                    <div className="col-md-9">
                                        <h3 className="heading heading--secondary"
                                            alt="document-Center-Upload-Label-Seven">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelSeven}/>
                                        </h3>
                                        <p alt="document-Center-Upload-Label-Eight">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelEight}/>
                                        </p>
                                        <hr/>
                                        <em className="bls-notification" alt="document-Center-Upload-Label-Nine">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelNine}/>
                                        </em>
                                    </div>
                                    <div className="col-md-3 bls-content-center">
                                        <br/>
                                        <button alt="document-Center-Upload-Label-Ten"
                                                className="button button--hollow bls-vertical float-right"
                                                onClick={this.triggerBrowseFile}>
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelTen}/>
                                        </button>
                                        <input type="file" name="files" className="hidden" ref="file"
                                               id="browse-files"
                                               alt="add file"
                                               disabled={this.missingDocType()}
                                               onChange={this.handleAddFile}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <table className="table xs-mu-20" data-table-standard="">
                                    <thead className="xs-hidden md-table-head">
                                    <tr>
                                        <th scope="col" alt="document-Center-Upload-Label-Eleven">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelEleven}/>
                                        </th>
                                        <th scope="col" alt="document-Center-Upload-Label-Twelve">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelTwelve}/>
                                        </th>
                                        <th scope="col" alt="document-Center-Upload-Label-Thirteen">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelThirteen}/>
                                        </th>
                                        <th scope="col" alt="document-Center-Upload-Label-Fourteen">
                                            <CmsContent pageName={CmsKeys.documentCenterUploadLabelFourteen}/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getFilesHtml()}
                                    {this.getAddDocumentsButton()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br/>
                        <br/>

                        {this.renderValidationError('files')}
                        {this.getServerError()}

                        <br/>
                        <div className="row bls-doc-tutorial">
                            <br/>
                            <div className="col-md-6" alt="document-Center-Upload-Label-Fifteen">
                                <CmsContent pageName={CmsKeys.documentCenterUploadLabelFifteen}/>
                            </div>
                            <div className="col-md-6">
                                <button
                                    className={`${this.state.isSubmitButtonDisabled ? 'button_inactive' : 'button'} float-right`}
                                    disabled={this.state.isSubmitButtonDisabled}
                                    onClick={this.handleSubmit}
                                    alt="document-Center-Upload-Label-Sixteen">
                                    {this.getButtonValue()}
                                </button>
                            </div>
                        </div>
                        <br/>
                    </div>
                </section>
            </main>
        )
    }
};

function mapStateToProps(state) {
    return {
        frontendProps: state.frontendProps,
        user: state.user,
        cmsContent: state.cmsContent
    };
}

Upload.propTypes = {
    frontendProps: PropTypes.object,
    user: PropTypes.object,
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(Upload);