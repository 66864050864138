import _ from 'lodash';

export function getTextFromContent(content, key) {
    return getTextFromContentWithAltText(content, key, '');
}

export function getTextFromContentWithAltText(content, key, alternativeText) {
    if (!content || _.isEmpty(content[key])) {
        return alternativeText;
    }
    return content[key].text;
}