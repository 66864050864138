import React from 'react';
import _ from 'lodash';

const GeneralModal = ({markup, onClose}) => {
    const _onClose = (event) => {
        event.preventDefault();
        onClose(event);
    };

    const renderGeneralModal = () => {
        if (_.isEmpty(markup)) {
            return null;
        }

        return (
            <div className="overlay">
                <div className="popup">
                    <a className="close" href="#" onClick={_onClose}>&times;</a>
                    <div className="content" dangerouslySetInnerHTML={{__html: markup}}></div>
                </div>
            </div>
        );
    }

    return renderGeneralModal();
};

export default GeneralModal;