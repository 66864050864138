class InputFocusClass {
    constructor() {
        document.querySelectorAll('.input-group .input, .input-group .text-area').forEach(el => {
            if (el.getAttribute('placeholder')) {
                el.closest('.input-group').classList.add('input-group--in-focus');
            } else {
                el.on('focusin', this.focus);
                el.on('focusout', this.noFocus);
                el.on('change', this.focus);
            }
            if (el.value || el.value.length > 0) {
                el.closest('.input-group').classList.add('input-group--in-focus');
            }
        });
    }

    focus(e) {
        let $input = e.target;
        let $inputGroup = $input.closest('.input-group');
        let inputVal = $input.value;

        if (($inputGroup && !$inputGroup.classList.contains('input-group--in-focus')) || inputVal) {
            $inputGroup.classList.add('input-group--in-focus');
        }
    }

    noFocus(e) {
        let $input = e.target;
        let $inputGroup = $input.closest('.input-group');
        let inputVal = $input.value;
        // Number inputs accept values like "e" and "." but are unable to parse the number, thus
        // resulting in an empty value. By checking the ValidityState property and seeing if
        // there was "badInput" that was unable to be parsed, we can determine there actually
        // IS something in the field even though it reports nothing.
        //
        // More:
        // - https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
        // - https://github.com/facebook/react/issues/11606
        // - https://github.com/facebook/react/issues/6556
        if ($inputGroup && (!inputVal || inputVal.length == 0) && (!$input.validity || !$input.validity.badInput)) {
            $inputGroup.classList.remove('input-group--in-focus');
        }
    }
}

export default InputFocusClass;