import React, {Component} from 'react';
import {Link} from "react-router-dom";
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class ShParentNavBar extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    getHeaderLinkHtml(linkTo, cmsKey) {
        return (
            <li key={cmsKey} className='main-header__nav-item'>
                <Link to={linkTo} className="main-header__link" alt={cmsKey}>
                        <span className="main-header__link-text">
                            <CmsContent pageName={cmsKey}/>
                        </span>
                </Link>
            </li>
        );
    }

    renderHeaderLinks() {
        let headerLinks = [];
        headerLinks.push(this.getHeaderLinkHtml('/loans', CmsKeys.shHeaderNavLinkLabelOne));

        if (this.props.loan) {
            headerLinks.push(this.getHeaderLinkHtml('/upload', CmsKeys.shHeaderNavLinkLabelTwo));
            headerLinks.push(this.getHeaderLinkHtml('/loaninformation', CmsKeys.shHeaderNavLinkLabelThree));
        }

        return (
            <ul className="main-header__nav-list">
                {headerLinks}
            </ul>
        );
    }

    render() {
        return (
            <nav className="main-header__nav" aria-labelledby="nav-label"><span className="sr-only" id="nav-label">Header</span>
                {this.renderHeaderLinks()}
            </nav>
        )
    }
};

function mapStateToProps(state) {
    return {
        loan: state.loan
    };
}

ShParentNavBar.propTypes = {
    loan: PropTypes.object
};

export default connect(mapStateToProps)(ShParentNavBar);