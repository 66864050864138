const DIGITS_ONLY_REGEX = new RegExp(/^\d+$/);
const l10nUSD = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export function isDigitsOnly(value) {
    if (!value) {
        return true;
    }

    return value.match(DIGITS_ONLY_REGEX);
}

export function isNotDigitsOnly(value) {
    return !isDigitsOnly(value);
}

/**
 * Formats a money value. Wraps with parenthesis and removes the minus sign if negative.
 * @param number
 * @returns formatted number, i.e. ($123.45)
 */
export function toMoneyFormat(number) {
    if (!number) {
        return number;
    }

    if (number >= 0) {
        return l10nUSD.format(number);
    }

    return '(' + l10nUSD.format(Math.abs(number)) + ')';
}

/**
 * Formats a money value. Converts to a number then wraps with parenthesis and removes the minus sign if negative.
 * @param str
 * @returns formatted, i.e. ($123.45)
 */
export function toMoneyFormatFromStr(str = '0') {
    return toMoneyFormat(convertStringToFloatNumber(str))
}

export function convertStringToFloatNumber(str) {
    if (!str) {
        return '';
    }

    return removeCommas(`${str}`) * 1;
}

export function removeCommas(str) {
    return removeCharacter(str, /,/g);
}

function removeCharacter(str, regex) {
    return (str.replace(regex, ''));
}
