class TableSelect {
  constructor($selects) {
    this.$selectAll = $selects;
    if (! this.$selectAll.length) {
      return false;
    }
    this.messageLocation = '/account/messages/message.html?id={id}'
    this.$parent         = this.$selectAll[0].closest('th') || this.$selectAll[0].closest('td');
    this.index           = this.$parent.index();
    this.$table          = this.$selectAll[0].closest('.table');
    this.$rows           = this.$table.querySelectorAll('[data-message-id]');
    this.$checkboxes     = Array.from(this.$table.querySelectorAll('tbody .checkbox__input')).filter(input => input.closest('td').index() == this.index); // Gets all checkboxes in the same column as the select all checkbox
    this.$tableOptions   = this.$table.parentNode.querySelector('[data-table-options]');

    this.$selectAll.forEach($el => {
      $el.on('click',this.toggleAll.bind(this));
    });
    this.$checkboxes.forEach($el => {
      $el.on('change',this.toggle.bind(this));
      $el.on('change',this.toggleOptions.bind(this));
    });

    this.$rows.forEach($el => {
      $el.on('click',this.view.bind(this));
    });
  }

  toggleAll(e) {
    let selected = e.target.checked;
    this.$checkboxes.forEach($el => {
      if( $el.disabled ) return;
      $el.checked = selected;
      $el.trigger('change');
    });
    let $announcementEl = e.target.parentNode.querySelector('[data-all-selected-announcement]');
    $announcementEl.removeChild($announcementEl.querySelector("p"));
    let $newEl = document.createElement("p");
    selected ? $newEl.textContent = $announcementEl.dataset.allSelectedAnnouncement : $newEl.textContent = $announcementEl.dataset.allUnselectedAnnouncement;
    $announcementEl.appendChild($newEl);
  }

  toggle(e) {
    let $checkbox = e.target;
    $checkbox.checked ? this.select($checkbox) : this.deselect($checkbox);

    // Hack for safari to keep the caption from floating to the bottom. Set it to the bottom,
    // then timeout to clear the stack and trigger rendering, then move back to top.
    if( /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ) {
      //$checkbox.closest('table').querySelector('caption').style.captionSide = 'bottom';
      setTimeout(() => {
        $checkbox.closest('table').querySelector('caption').style.captionSide = 'top';
      });
    }
  }

  toggleOptions() {
    if(this.$tableOptions) {
      let att = this.$table.querySelectorAll('tbody .checkbox__input:checked').length ? true : false;
      setTimeout( () => {
        this.$tableOptions.setAttribute('data-table-options',att);
        if( att ) {
          this.$tableOptions.classList.add('data-table-options--visible');
        } else {
          this.$tableOptions.classList.remove('data-table-options--visible');
        }
      });
    }

    // Make sure the select all checkboxes get updated (checked for all checked, unchecked for any unchecked)
    this.$selectAll.forEach($el => {
      $el.checked = this.$checkboxes.filter(input => input.checked).length === this.$checkboxes.length;
    });
  }

  checkInput($el) {
    $el.checked = true;
  }

  uncheckInput($el) {
    $el.checked = false;
  }

  select($el) {
    let $row = $el.closest('.table__row');
    if( $row ) {
      $row.classList.add('table__row--selected');
    }
  }

  deselect($el) {
    let $row = $el.closest('.table__row');
    if( $row ) {
      $row.classList.remove('table__row--selected');
    }
  }

  view(e) {
    let $target = e.target;
    if($target.nodeName === 'TD' ) {
      let id = $target.closest('[data-message-id]').getAttribute('data-message-id');
      window.location.href = this.messageLocation.replace('{id}',id);
    }
  }
}

// Checks in the head for a select, finds a corresponding select all in the footer, creates a new select all option
let $headerSelects = document.querySelectorAll('thead [data-select-all]');
if( $headerSelects.length ) {
  $headerSelects.forEach( ($select) => {
    let $selects       = [$select];
    let index          = $select.closest('th').index();
    let $table         = $select.closest('table');
    let $footerSelects = $table.querySelectorAll('tfoot [data-select-all]');

    if( $footerSelects.length ) {
      $selects = $selects.concat(Array.from($footerSelects).filter($footSelect => $footSelect.closest('td').index() == index ));
    }

    new TableSelect($selects);
  });
}
