import React, {Component} from 'react';
import ApiConstants from 'js/utils/apiConstants';
import axios from 'axios';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Loading from 'components/Loading';
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import _ from 'lodash';
import Moment from "react-moment";
import ShowHide from "js/external/components/show-hide";

class ShUploadHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            uploadHistory: [],
            uploadHistorySubsetHtml: [],
            serverError: false
        };

        this.loadMoreUploadHistory = this.loadMoreUploadHistory.bind(this);
    }

    componentDidMount() {
        this.setState({loading: true});
        const url = ApiConstants.DOCUMENT_HISTORY_API_URL.replace('{loanNumber}', this.props.loan.loanNumber);

        axios.get(url).then(result => {
            this.setState({uploadHistory: result.data, loading: false});

            if (!_.isEmpty(this.state.uploadHistory)) {
                this.loadUploadHistorySubset(0, 4);
            }
        }, (error) => {
            this.setState({serverError: true, loading: false})
        });
    }

    componentDidUpdate() {
        new ShowHide();
    }

    loadMoreUploadHistory() {
        this.loadUploadHistorySubset(0);
    }

    loadUploadHistorySubset(start, end) {
        let uploadHistorySubset = this.state.uploadHistory.slice(start, end);

        let uploadHistorySubsetHtml = uploadHistorySubset.map((e, i) =>
            <tr key={`upload-${i}`}>
                <td data-th="Upload Date"><Moment date={e.createdDate} format="MM/DD/YYYY"/></td>
                <td data-th="Description">{e.docType}</td>
                <td data-th="File Name">{e.filename}</td>
            </tr>
        );

        this.setState({uploadHistorySubsetHtml: uploadHistorySubsetHtml});
    }

    getEmptyTableVerbiage() {
        return this.state.serverError ? CmsKeys.shUploadHistoryErrorVerbiage : CmsKeys.shUploadHistoryTwoVerbiage;
    }

    renderEmptyUploadHistoryTable() {
        return (
            <table className="table xs-mu-20" data-table-standard>
                <caption>
                    <span className="sr-only">This table shows</span>Your Uploaded Documents<span className="sr-only">
                    <CmsContent pageName={CmsKeys.shUploadHistoryOneVerbiage}/>
                    </span>
                </caption>
                <tbody>
                <tr>
                    <td colSpan="3">
                        <em alt={this.getEmptyTableVerbiage()}>
                            <CmsContent pageName={this.getEmptyTableVerbiage()}/>
                        </em>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    renderUploadHistoryTable() {
        return (
            <table className="table" data-table-standard>
                <caption><span className="sr-only">This table shows</span>Your Uploaded Documents<span className="sr-only">Table columns include upload date, description, and document name.</span>
                </caption>
                <thead className="xs-hidden md-table-head">
                <tr>
                    <th scope="col" alt={CmsKeys.shUploadHistoryUploadDateLabel}>
                        <CmsContent pageName={CmsKeys.shUploadHistoryUploadDateLabel}/>
                    </th>
                    <th scope="col" alt={CmsKeys.shUploadHistoryDescriptionLabel}>
                        <CmsContent pageName={CmsKeys.shUploadHistoryDescriptionLabel}/>
                    </th>
                    <th scope="col" alt={CmsKeys.shUploadHistoryFilenameLabel}>
                        <CmsContent pageName={CmsKeys.shUploadHistoryFilenameLabel}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {this.state.uploadHistorySubsetHtml}
                </tbody>
                <tfoot className="table__cta">
                <tr>
                    <td colSpan="3"><a role="button" href="#" data-show="[data-expand]" data-hide="self" onClick={this.loadMoreUploadHistory}>View More<span
                        className="sr-only">uploaded documents</span></a>
                    </td>
                </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        if (this.state.loading) {
            return <Loading/>;
        }

        return _.isEmpty(this.state.uploadHistory) ? this.renderEmptyUploadHistoryTable() : this.renderUploadHistoryTable();
    }
};

function mapStateToProps(state) {
    return {
        frontendProps: state.frontendProps,
        user: state.user,
        cmsContent: state.cmsContent,
        loan: state.loan
    };
}

ShUploadHistory.propTypes = {
    frontendProps: PropTypes.object,
    user: PropTypes.object,
    cmsContent: PropTypes.object,
    loan: PropTypes.object
};

export default connect(mapStateToProps)(ShUploadHistory);