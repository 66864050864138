import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getToday} from 'js/utils/dateUtils';
import _ from 'lodash';
import CmsContent from 'components/CmsContent';
import CmsKeys from 'js/utils/cmsKeys';
import path from 'path';

const UploadConfirmation = ({email, uploadResults, user}) => {

    const ERROR = "ERROR";
    const SUCCESS = "SUCCESS";
    const CORRUPTED = "CORRUPTED";
    const ENCRYPTED = "ENCRYPTED";

    const getUploadResultsHtml = () => {
        return uploadResults.map((e, i) =>
            <tr key={`upload-result-${i}`}>
                <td data-column="Upload Date "> {getToday()}</td>
                <td data-column="Document Type "> {e.type}</td>
                <td data-column="File Name "> {path.basename(e.path)}</td>
                <td data-column="Upload Status "> {getUploadResultStatus(e.status)}</td>
            </tr>
        );
    };

    const getUploadResultStatus = (status = '') => {
        if (_.includes(status.toUpperCase(), SUCCESS)) {
            return SUCCESS;
        }
        return buildErrorResultStatus(status);
    };

    const buildErrorResultStatus = (status) => {
        let description = "";
        if (_.includes(status.toUpperCase(), CORRUPTED)) {
            description += "File is corrupted. ";
        }
        if (_.includes(status.toUpperCase(), ENCRYPTED)) {
            description += "File is encrypted or password protected. ";
        }
        return ERROR + " > " + description;
    };

    const getEmailVerbiage = () => {
        if (!email) {
            return getUniqueIdVerbiage();
        }

        return (
            <div>
                <span alt="document-Center-Confirmation-Label-One">
                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelOne}/>
                </span>
                &nbsp;<strong>{email}</strong> &nbsp;<br/><br/>
                {getUniqueIdVerbiage()}
            </div>
        )
    };

    const getUniqueIdVerbiage = () => {
        return (
            <div>
                <span alt="document-Center-Confirmation-Label-Two">
                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelTwo}/>
                </span>
                &nbsp;<strong>{user.uniqueId}</strong>.&nbsp;
            </div>
        );
    };

    return (
        <section className="section section--shadow doc_padding_control">
            <div className="marquee">
                <h1 className="heading heading--ko">
                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelEleven}/>
                </h1>
            </div>
            <div className="container">
                <br />

                <div className="text-center">
                    <h1 className="heading heading--tertiary" alt="document-Center-Confirmation-Label-Three">
                        <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelThree}/>
                    </h1>
                    {getEmailVerbiage()}
                    <br/>
                    <Link className="button" to={"/upload"} alt="document-Center-Confirmation-Label-Four">
                        <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelFour}/>
                    </Link>
                </div>

                <br/>

                <div className="bls-doc-uploads">
                    <table className="table xs-mu-20">
                        <thead className="xs-hidden md-table-head">
                        <tr>
                            <th scope="col" data-th="Upload Date" alt="document-Center-Confirmation-Label-Five">
                                <u>
                                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelFive}/>
                                </u></th>
                            <th scope="col" data-th="Type" alt="document-Center-Confirmation-Label-Six">
                                <u>
                                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelSix}/>
                                </u>
                            </th>
                            <th scope="col" data-th="File Name" alt="document-Center-Confirmation-Label-Seven">
                                <u>
                                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelSeven}/>
                                </u></th>
                            <th scope="col" data-th="Upload Status" alt="document-center-confirmation-label-ten">
                                <u>
                                    <CmsContent pageName={CmsKeys.documentCenterConfirmationLabelTen}/>
                                </u></th>
                        </tr>
                        </thead>
                        <tbody>
                        {getUploadResultsHtml()}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
};

function mapStateToProps(state, props) {
    return {
        email: props.location.state.email,
        uploadResults: props.location.state.uploadResults,
        user: state.user
    };
}

UploadConfirmation.propTypes = {
    email: PropTypes.string,
    uploadResults: PropTypes.array,
    user: PropTypes.object
};


export default connect(mapStateToProps)(UploadConfirmation);