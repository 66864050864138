const CmsKeys = {
    //Header URL - Link
    documentCenterHeaderLogoImageUrl: 'document-center-header-logo-image-url',

    //Footer URL - Link
    documentCenterfooterLinkOne: 'document-center-footer-link-one',
    documentCenterfooterLinkTwo: 'document-center-footer-link-two',
    documentCenterfooterLinkThree: 'document-center-footer-link-three',
    documentCenterfooterLinkFour: 'document-center-footer-link-four',
    documentCenterfooterLinkFive: 'document-center-footer-link-five',
    documentCenterfooterLinkSix: 'document-center-footer-link-six',
    documentCenterFooterLogoImageUrl: 'document-center-footer-logo-image-url',

    //Footer URL - Link
    shDocumentCenterfooterLinkOne: 'sh-document-center-footer-link-one',
    shDocumentCenterfooterLinkTwo: 'sh-document-center-footer-link-two',
    shDocumentCenterfooterLinkThree: 'sh-document-center-footer-link-three',
    shDocumentCenterfooterLinkFour: 'sh-document-center-footer-link-four',
    shDocumentCenterfooterLinkFive: 'sh-document-center-footer-link-five',
    shDocumentCenterfooterLinkSix: 'sh-document-center-footer-link-six',

    //Footer Links Label
    documentCenterfooterLinkLabelOne: 'document-center-footer-link-label-one',
    documentCenterfooterLinkLabelTwo: 'document-center-footer-link-label-two',
    documentCenterfooterLinkLabelThree: 'document-center-footer-link-label-three',
    documentCenterfooterLinkLabelFour: 'document-center-footer-link-label-four',
    documentCenterfooterLinkLabelFive: 'document-center-footer-link-label-five',
    documentCenterfooterLinkLabelSix: 'document-center-footer-link-label-six',

    // Silver Hill - Footer  links Label
    shDocumentCenterfooterLinkLabelOne: 'sh-document-center-footer-link-label-one',
    shDocumentCenterfooterLinkLabelTwo: 'sh-document-center-footer-link-label-two',
    shDocumentCenterfooterLinkLabelThree: 'sh-document-center-footer-link-label-three',
    shDocumentCenterfooterLinkLabelFour: 'sh-document-center-footer-link-label-four',
    shDocumentCenterfooterLinkLabelFive: 'sh-document-center-footer-link-label-five',
    shDocumentCenterfooterLinkLabelSix: 'sh-document-center-footer-link-label-six',

    //Login / Landing page Links and Label
    documentCenterMainTitle: 'document-center-main-title',
    documentCenterMainLabelOne: 'document-center-main-label-one',
    documentCenterMainLabelTwo: 'document-center-main-label-two',
    documentCenterMainLabelThree: 'document-center-main-label-three',
    documentCenterAnnouncementPopup: 'document-center-announcement-popup',
    documentCenterMrcLandingPage: 'document-center-mrc-landing-page',
    documentCenterRpsLandingPage: 'document-center-rps-landing-page',
    documentCenterOtherServicerLandingPage: 'document-center-other-servicer-landing-page',

    //Silver Hill / Login / Landing page Links and Label
    shDocumentCenterMainTitle: 'sh-document-center-main-title',

    shDocumentCenterMainLabelOne: 'sh-document-center-main-label-one',
    shDocumentCenterMainLabelTwo: 'sh-document-center-main-label-two',
    shDocumentCenterMainLabelThree: 'sh-document-center-main-label-three',

    //Upload page Links and Label
    documentCenterUploadNotification: 'document-center-upload-notification',
    documentCenterUploadLabelOne: 'document-center-upload-label-one',
    documentCenterUploadLabelTwo: 'document-center-upload-label-two',
    documentCenterUploadLabelThree: 'document-center-upload-label-three',
    documentCenterUploadLabelFour: 'document-center-upload-label-four',
    documentCenterUploadLabelFive: 'document-center-upload-label-five',
    documentCenterUploadLabelSix: 'document-center-upload-label-six',
    documentCenterUploadLabelSeven: 'document-center-upload-label-seven',
    documentCenterUploadLabelEight: 'document-center-upload-label-eight',
    documentCenterUploadLabelNine: 'document-center-upload-label-nine',
    documentCenterUploadLabelTen: 'document-center-upload-label-ten',
    documentCenterUploadLabelEleven: 'document-center-upload-label-eleven',
    documentCenterUploadLabelTwelve: 'document-center-upload-label-twelve',
    documentCenterUploadLabelThirteen: 'document-center-upload-label-thirteen',
    documentCenterUploadLabelFourteen: 'document-center-upload-label-fourteen',
    documentCenterUploadLabelFifteen: 'document-center-upload-label-fifteen',
    documentCenterUploadLabelSixteen: 'document-center-upload-label-sixteen',
    documentCenterUploadLabelSeventeen: 'document-center-upload-label-seventeen',
    documentCenterUploadLabelEighteen: 'document-center-upload-label-eighteen',
    documentCenterUploadLabelNineteen: 'document-center-upload-label-nineteen',
    documentCenterUploadLabelTwenty: 'document-center-upload-label-twenty',

    //Silver Hill - Loans page
    shLoansNotification: 'sh-loans-notification',
    shTableHeaderOne: 'sh-table-header-one',
    shTableHeaderTwo: 'sh-table-header-two',
    shLoanButton: 'sh-loan-button',
    shNoLoansFound: 'sh-no-loans-found',

    //Silver Hill - Upload page Links and Label
    shDocumentCenterUploadNotification: 'sh-document-center-upload-notification',
    shDocumentCenterUploadLabelOne: 'sh-document-center-upload-label-one',
    shDocumentCenterUploadLabelTwo: 'sh-document-center-upload-label-two',
    shDocumentCenterUploadLabelThree: 'sh-document-center-upload-label-three',
    shDocumentCenterUploadLabelFour: 'sh-document-center-upload-label-four',
    shDocumentCenterUploadLabelFive: 'sh-document-center-upload-label-five',
    shDocumentCenterUploadLabelSix: 'sh-document-center-upload-label-six',
    shDocumentCenterUploadLabelSeven: 'sh-document-center-upload-label-seven',
    shDocumentCenterUploadLabelEight: 'sh-document-center-upload-label-eight',
    shDocumentCenterUploadLabelNine: 'sh-document-center-upload-label-nine',
    shDocumentCenterUploadLabelTen: 'sh-document-center-upload-label-ten',
    shDocumentCenterUploadLabelEleven: 'sh-document-center-upload-label-eleven',
    shDocumentCenterUploadLabelTwelve: 'sh-document-center-upload-label-twelve',
    shDocumentCenterUploadLabelThirteen: 'sh-document-center-upload-label-thirteen',
    shDocumentCenterUploadLabelFourteen: 'sh-document-center-upload-label-fourteen',
    shDocumentCenterUploadLabelFifteen: 'sh-document-center-upload-label-fifteen',
    shDocumentCenterUploadLabelSixteen: 'sh-document-center-upload-label-sixteen',
    shDocumentCenterUploadLabelSeventeen: 'sh-document-center-upload-label-seventeen',
    shDocumentCenterUploadLabelEighteen: 'sh-document-center-upload-label-eighteen',
    shDocumentCenterUploadLabelNineteen: 'sh-document-center-upload-label-nineteen',
    shDocumentCenterUploadLabelTwenty: 'sh-document-center-upload-label-twenty',
    shDocumentCenterUploadConfirmationTitle: 'sh-document-center-upload-confirmation-title',
    shDocumentCenterUploadConfirmationMessage: 'sh-document-center-upload-confirmation-message',

    //Silver Hill - Upload History
    shUploadHistoryOneVerbiage: 'sh-upload-history-one-verbiage',
    shUploadHistoryTwoVerbiage: 'sh-upload-history-two-verbiage',
    shUploadHistoryErrorVerbiage: 'sh-upload-history-error-verbiage',
    shUploadHistoryUploadDateLabel: 'sh-upload-history-uploaded-date-label',
    shUploadHistoryDescriptionLabel: 'sh-upload-history-description-label',
    shUploadHistoryFilenameLabel: 'sh-upload-history-filename-label',

    //Confirmation page Links and Label
    documentCenterConfirmationLabelOne: 'document-center-confirmation-label-one',
    documentCenterConfirmationLabelTwo: 'document-center-confirmation-label-two',
    documentCenterConfirmationLabelThree: 'document-center-confirmation-label-three',
    documentCenterConfirmationLabelFour: 'document-center-confirmation-label-four',
    documentCenterConfirmationLabelFive: 'document-center-confirmation-label-five',
    documentCenterConfirmationLabelSix: 'document-center-confirmation-label-six',
    documentCenterConfirmationLabelSeven: 'document-center-confirmation-label-seven',
    documentCenterConfirmationLabelEight: 'document-center-confirmation-label-eight',
    documentCenterConfirmationLabelNine: 'document-center-confirmation-label-nine',
    documentCenterConfirmationLabelTen: 'document-center-confirmation-label-ten',
    documentCenterConfirmationLabelEleven: 'document-center-confirmation-label-eleven',

    //Silver Hill / Confirmation page Links and Label
    shDocumentCenterConfirmationLabelOne: 'sh-document-center-confirmation-label-one',
    shDocumentCenterConfirmationLabelTwo: 'sh-document-center-confirmation-label-two',
    shDocumentCenterConfirmationLabelThree: 'sh-document-center-confirmation-label-three',
    shDocumentCenterConfirmationLabelFour: 'sh-document-center-confirmation-label-four',
    shDocumentCenterConfirmationLabelFive: 'sh-document-center-confirmation-label-five',
    shDocumentCenterConfirmationLabelSix: 'sh-document-center-confirmation-label-six',
    shDocumentCenterConfirmationLabelSeven: 'sh-document-center-confirmation-label-seven',
    shDocumentCenterConfirmationLabelEight: 'sh-document-center-confirmation-label-eight',
    shDocumentCenterConfirmationLabelNine: 'sh-document-center-confirmation-label-nine',
    shDocumentCenterConfirmationLabelTen: 'sh-document-center-confirmation-label-ten',
    shDocumentCenterConfirmationLabelEleven: 'sh-document-center-confirmation-label-eleven',

    //Registration Form page Links and Labels
    documentCenterRegistrationFormLabelOne: 'document-center-registration-form-label-one',
    documentCenterRegistrationFormLabelTwo: 'document-center-registration-form-label-two',

    documentCenterRegistrationFormErrorOne: 'document-center-registration-form-error-one',
    documentCenterRegistrationFormErrortwo: 'document-center-registration-form-error-two',

    documentCenterRegistrationFormErrorThree: 'document-center-registration-form-error-three',
    documentCenterRegistrationFormErrorFour: 'document-center-registration-form-error-four',
    documentCenterRegistrationFormErrorFive: 'document-center-registration-form-error-five',
    documentCenterRegistrationFormErrorSix: 'document-center-registration-form-error-six',

    //Silver Hill - Registration Form page Links and Labels
    shDocumentCenterRegistrationFormLabelOne: 'sh-document-center-registration-form-label-one',
    shDocumentCenterRegistrationFormLabelTwo: 'sh-document-center-registration-form-label-two',

    shDocumentCenterRegistrationFormErrorOne: 'sh-document-center-registration-form-error-one',
    shDocumentCenterRegistrationFormErrortwo: 'sh-document-center-registration-form-error-two',

    shDocumentCenterRegistrationFormErrorThree: 'sh-document-center-registration-form-error-three',
    shDocumentCenterRegistrationFormErrorFour: 'sh-document-center-registration-form-error-four',

    shDocumentCenterRegistrationFormErrorFive: 'sh-document-center-registration-form-error-five',
    shDocumentCenterRegistrationFormErrorSix: 'sh-document-center-registration-form-error-six',

    //Copyright footer information
    nmlsCopyRight: 'sh-document-center-nmls-copy-right',
    nmlsLabelLink: 'sh-document-center-nmls-label-link',
    nmlsUrl: 'sh-document-center-nmls-url',
    nmlsCopyAllRightsReserved: 'sh-document-center-nmls-copy-all-rights-reserved',

    //SilverHill Copyright footer information
    shNmlsCopyRight: 'sh-document-center-nmls-copy-right',
    shNmlsLabelLink: 'sh-document-center-nmls-label-link',
    shNmlsUrl: 'sh-document-center-nmls-url',
    shNmlsCopyAllRightsReserved: 'sh-document-center-nmls-copy-all-rights-reserved',

    // 404 page
    notFoundHeaderLabelOne: 'document-center-not-found-header-label-one',
    notFoundHeaderLabelTwo: 'document-center-not-found-header-label-two',
    notFoundHeaderLabelThree: 'document-center-not-found-header-label-three',
    notFoundHeaderLabelFour: 'document-center-not-found-header-label-four',
    notFoundHeaderLabelFive: 'document-center-not-found-header-label-five',
    notFoundHeaderLinkForLabelFive: 'document-center-not-found-header-link-for-label-five',

    // SilverHill 404 page
    shNotFoundHeaderLabelOne: 'sh-document-center-not-found-header-label-one',
    shNotFoundHeaderLabelTwo: 'sh-document-center-not-found-header-label-two',
    shNotFoundHeaderLabelThree: 'sh-document-center-not-found-header-label-three',
    shNotFoundHeaderLabelFour: 'sh-document-center-not-found-header-label-four',
    shNotFoundHeaderLabelFive: 'sh-document-center-not-found-header-label-five',
    shNotFoundHeaderLinkForLabelFive: 'sh-document-center-not-found-header-link-for-label-five',

    //Technical Difficulties Page
    technicalDifficultiesPageTitle: 'document-center-technical-difficulties-page-title',
    technicalDifficultiesPageMessage: 'document-center-technical-difficulties-page-message',

    //SilverHill Technical Difficulties Page
    shTechnicalDifficultiesPageTitle: 'sh-document-center-technical-difficulties-page-title',
    shTechnicalDifficultiesPageMessage: 'sh-document-center-technical-difficulties-page-message',

    // SilverHill header
    shHeaderNavLinkLabelOne: 'sh-header-nav-link-label-one',
    shHeaderNavLinkLabelTwo: 'sh-header-nav-link-label-two',
    shHeaderNavLinkLabelThree: 'sh-header-nav-link-label-three',

    // SilverHill Loan Information Page
    shLoanInfoTitle: 'sh-loan-info-title',
    shLoanInfoParentRowLabelOne: 'sh-loan-info-parent-row-label-one',
    shLoanInfoParentRowLabelTwo: 'sh-loan-info-parent-row-label-two',
    shLoanInfoParentRowLabelThree: 'sh-loan-info-parent-row-label-three',
    shLoanInfoParentRowLabelFour: 'sh-loan-info-parent-row-label-four',
    shLoanInfoChildRowLabelOne: 'sh-loan-info-child-row-label-one',
    shLoanInfoChildRowLabelTwo: 'sh-loan-info-child-row-label-two',
    shLoanInfoChildRowLabelThree: 'sh-loan-info-child-row-label-three',
    shLoanInfoChildRowLabelFour: 'sh-loan-info-child-row-label-four',
    shLoanInfoChildRowLabelFive: 'sh-loan-info-child-row-label-five',
    shLoanInfoChildRowLabelSix: 'sh-loan-info-child-row-label-six',
    shLoanInfoChildRowLabelSeven: 'sh-loan-info-child-row-label-seven',
    shLoanInfoChildRowLabelEight: 'sh-loan-info-child-row-label-eight',
    shLoanInfoChildRowLabelNine: 'sh-loan-info-child-row-label-nine',
    shLoanInfoChildRowLabelTen: 'sh-loan-info-child-row-label-ten',
    shLoanInfoChildRowLabelEleven: 'sh-loan-info-child-row-label-eleven',
    shLoanInfoChildRowLabelTwelve: 'sh-loan-info-child-row-label-twelve'
};

export default CmsKeys;