import {getTextFromContent} from 'js/utils/cmsUtils';
import {connect} from 'react-redux';
import PropTypes from "prop-types";

const CmsContent = ({pageName, cmsContent}) => {
    return getTextFromContent(cmsContent, pageName);
};

function mapStateToProps(state) {
    return {
        cmsContent: state.cmsContent
    };
}

CmsContent.propTypes = {
    pageName: PropTypes.string,
    cmsContent: PropTypes.object
};

export default connect(mapStateToProps)(CmsContent);